import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { retry } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { LoggerService } from '@stsm/shared/logger/logger.service';

const GOOGLE_ADSENSE_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

@Injectable({
  providedIn: 'root',
})
export class AdBlockerDetectorService {
  constructor(
    private readonly _loggerService: LoggerService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _httpClient: HttpClient,
  ) {}

  init(): void {
    this._sendAdTestRequest();
  }

  /**
   * Send a request to the Google AdSense URL to check if an AdBlocker is active.
   * If the first request fails, we wait 2 seconds and try again, in total 2 times.
   * If all requests fail, we assume an AdBlocker is active.
   *
   * @private
   */
  private _sendAdTestRequest(): void {
    this._httpClient
      .head(GOOGLE_ADSENSE_URL, {
        headers: {
          mode: 'no-cors',
        },
      })
      .pipe(
        retry({
          count: 2,
          delay: 2000,
        }),
      )
      .subscribe({
        error: (error: Error) => {
          this._loggerService.debug('AdBlocker detected. Ad test-request failed: ', error);

          this._trackAdBlockerDetectionEvent(true);
        },
        next: () => {
          this._loggerService.debug('No AdBlocker detected. Ad test-request succeeded.');

          this._trackAdBlockerDetectionEvent(false);
        },
      });
  }

  private _trackAdBlockerDetectionEvent(isAdBlockerDetected: boolean): void {
    this._analyticsService.trackEvent({
      action: 'adblocker_check',
      category: 'monetization',
      properties: {
        isAdBlockerDetected,
      },
    });
  }
}
