@if (!isLoadingMessage) {
  @if (showLoadingSpinner()) {
    <ui-spinner [size]="14" />
  } @else if (iconName()) {
    <i [ngClass]="iconName()"></i>
  }

  <div appLatexRenderer [contentWithLatex]="parsedMessage() | markdownToHtml"></div>
} @else {
  <app-response-loading-animation />
}
