import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { BetaBadgeComponent } from '@stsm/ui-components/beta-badge/beta-badge.component';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DotBadgeComponent } from '@stsm/ui-components/dot-badge/dot-badge.component';
import { AnimatedIllustrationDirective } from '@stsm/ui-components/lottie-view/animated-illustration.directive';

import { PillMessage } from '../../models/pill-message';
import { AssistantAnimationState } from '../../services/ai-assistant-store.service';
import { aiAssistantAnimations } from '../ai-assistant/ai-assistant-animations';

@Component({
  selector: 'app-chat-top-row',
  standalone: true,
  imports: [
    BetaBadgeComponent,
    ButtonComponent,
    TranslatePipe,
    NgIf,
    AnimatedIllustrationDirective,
    IconButtonComponent,
    DotBadgeComponent,
  ],
  templateUrl: './chat-top-row.component.html',
  styleUrl: './chat-top-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [aiAssistantAnimations.assistantTopBarContentAnimation, aiAssistantAnimations.assistantAvatarAnimation],
})
export class ChatTopRowComponent {
  @Input() animationState: AssistantAnimationState = 'button';
  @Input({ required: true }) isGeneratingResponse: boolean = false;
  @Input({ required: true }) isAnimatingAvatar: boolean = false;
  @Input() showNotification: boolean = false;
  @Input() pillMessage?: PillMessage;
  @Input() isRefreshDisabled?: boolean = false;

  @Output() readonly resetAssistant: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly closeAssistant: EventEmitter<void> = new EventEmitter<void>();
}
