import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { debounceTime, finalize, firstValueFrom, tap } from 'rxjs';

import { DevtoolsActionLabel, DevtoolsService } from '@stsm/devtools/services/devtools.service';
import { GlobalElementId } from '@stsm/global/models/enums/element-id';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { PremiumCountdownBannerComponent } from '@stsm/premium/components/premium-countdown-reset-banner/premium-countdown-banner.component';
import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { KeyboardService } from '@stsm/shared/services/keyboard.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import type { Id } from '@stsm/shared/types/id';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { ConfettiCanvasComponent } from '@stsm/ui-components/confetti-canvas';
import { CounterComponent } from '@stsm/ui-components/counter/counter.component';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { DotBadgeComponent } from '@stsm/ui-components/dot-badge/dot-badge.component';
import { TextSubmitInputComponent } from '@stsm/ui-components/text-submit-input/text-submit-input.component';

import { SCROLL_TO_BOTTOM_OF_THE_CHAT_DELAY } from '../../constants/animation-timing';
import { getChatActionActiveMessageKey, getChatActionDoneMessageKey, getChatActionIconName } from '../../constants/chat-action-assets';
import type { AiAssistantMessage } from '../../models/conversation/message';
import type { AiAssistantFileInputResult } from '../../models/file-input';
import { isAiAssistantFileInputDocument, isAiAssistantFileInputError, isAiAssistantFileInputImage } from '../../models/file-input';
import { AiAssistantActionHandlerService } from '../../services/ai-assistant-action-handler.service';
import { AiAssistantFileInputDialogService } from '../../services/ai-assistant-file-input-dialog.service';
import { AiAssistantPremiumService, AiAssistantPremiumViewModel } from '../../services/ai-assistant-premium.service';
import { AiAssistantStore, AiAssistantViewModel } from '../../services/ai-assistant-store.service';
import { AiNotificationService } from '../../services/ai-notification.service';
import { ChatConversationComponent } from '../chat-conversation/chat-conversation.component';
import { ChatTopRowComponent } from '../chat-top-row/chat-top-row.component';

import { aiAssistantAnimations } from './ai-assistant-animations';

@UntilDestroy()
@Component({
  selector: 'app-ai-assistant',
  standalone: true,
  imports: [
    ChatTopRowComponent,
    ChatConversationComponent,
    TextSubmitInputComponent,
    ButtonComponent,
    CounterComponent,
    NgIf,
    TranslatePipe,
    AsyncPipe,
    PremiumCountdownBannerComponent,
    IconButtonComponent,
    DotBadgeComponent,
    ConfettiCanvasComponent,
  ],
  templateUrl: './ai-assistant.component.html',
  styleUrl: './ai-assistant.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    aiAssistantAnimations.assistantFadeUpAnimation,
    aiAssistantAnimations.assistantFadeInOutAnimation,
    aiAssistantAnimations.assistantFadeInOutScaleAnimation,
  ],
  host: {
    '[class.chat]': `vm.isChatOpen()`,
    ['data-cy']: 'ai-assistant',
  },
})
export class AiAssistantComponent implements OnInit, OnDestroy {
  @Output() readonly closeAssistant: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer?: ElementRef<HTMLElement>;

  @ViewChild(ConfettiCanvasComponent)
  set confettiCanvas(confettiCanvas: ConfettiCanvasComponent) {
    this._aiAssistantActionHandlerService.registerConfettiCanvas(confettiCanvas);
  }

  @Input()
  set showChatContent(showChatContent: boolean) {
    this._showChatContent = showChatContent;

    if (showChatContent && this._aiAssistantStore.allMessages().length > 0) {
      setTimeout(() => this.scrollToBottom(), SCROLL_TO_BOTTOM_OF_THE_CHAT_DELAY);
    }
  }

  get showChatContent(): boolean {
    return this._showChatContent;
  }

  protected readonly mediaTooltipElementId: string = GlobalElementId.AI_ASSISTANT_MEDIA_INPUT_BUTTON;
  protected readonly vm: AiAssistantViewModel = this._aiAssistantStore.vm;
  protected readonly premiumVm: AiAssistantPremiumViewModel = this._aiAssistantPremiumService.vm;
  protected readonly allMessages: Signal<AiAssistantMessage[]> = this._aiAssistantStore.allMessages;
  protected readonly showScrollToBottomButton: WritableSignal<boolean> = signal(false);
  protected readonly showBottomSheetToggle: Signal<boolean> = computed(
    () => !this._keyboardService.isKeyboardVisible() && this._layoutStore.isMobileLayout(),
  );

  private _showChatContent: boolean = false;

  constructor(
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    @Inject(PREMIUM_MODAL_SERVICE) private readonly _premiumModalService: PremiumModalService,
    private readonly _aiAssistantFileInputDialogService: AiAssistantFileInputDialogService,
    private readonly _aiAssistantPremiumService: AiAssistantPremiumService,
    private readonly _aiNotificationService: AiNotificationService,
    private readonly _aiAssistantActionHandlerService: AiAssistantActionHandlerService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _aiAssistantStore: AiAssistantStore,
    private readonly _keyboardService: KeyboardService,
    private readonly _layoutStore: LayoutStore,
    private readonly _elementRef: ElementRef,
    private readonly _overlay: Overlay,
    private readonly _devtoolsService: DevtoolsService,
  ) {
    this._aiAssistantStore.allMessages$.pipe(debounceTime(0), untilDestroyed(this)).subscribe(() => {
      this.scrollToBottom();
    });

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.TRIGGER_AI_NOTIFICATION,
      action: () => {
        void firstValueFrom(this._aiNotificationService.sendContextSpecificAiNotification({ hasPriority: true }));
        this._devtoolsService.closeDevtools();
      },
    });
  }

  ngOnInit(): void {
    this._aiNotificationService.setActive(true);
  }

  ngOnDestroy(): void {
    this._devtoolsService.unregisterAdditionalAction(DevtoolsActionLabel.TRIGGER_AI_NOTIFICATION);
    this._aiNotificationService.setActive(false);
  }

  sendMessage(message: string, isCustom: boolean = true): void {
    this._aiAssistantStore.sendUserMessage(message, isCustom);
    this._aiAssistantPremiumService.increaseMessageCount();
  }

  onOptionClick(option: string): void {
    this.sendMessage(option, false);
  }

  onScroll(event: Event): void {
    const target: HTMLDivElement = event.target as HTMLDivElement;
    const scrollThreshold = 200; // the button will appear only after the user scrolled 200px from the bottom
    const showScrollToBottomButton =
      target.scrollHeight > target.offsetHeight + scrollThreshold &&
      target.scrollTop < target.scrollHeight - target.offsetHeight - scrollThreshold;

    this.showScrollToBottomButton.set(showScrollToBottomButton);
  }

  openAssistant(): void {
    if (!this.vm.isChatOpen()) {
      this._aiAssistantStore.openChat('user');
    }
  }

  toggleChatHeight(event: TouchEvent | MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this._aiAssistantStore.setHasReducedHeightFromUser(!this.vm.hasReducedHeight());
  }

  reset(): void {
    this._simpleDialogService.confirm({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef)
        .withPositions([{ originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'center' }]),
      heading: 'AI_ASSISTANT.CHAT.RESET.HEADING',
      subheading: 'AI_ASSISTANT.CHAT.RESET.SUBHEADING',
      confirmText: 'AI_ASSISTANT.CHAT.RESET.CONFIRM_BUTTON',
      confirmHandler: () => {
        this._aiAssistantStore.reset();
      },
    });
  }

  scrollToBottom(): void {
    this.scrollContainer?.nativeElement.scrollTo({ top: this.scrollContainer?.nativeElement.scrollHeight, behavior: 'smooth' });
  }

  addFile(): void {
    this._aiAssistantStore.setIgnoreOpenDialogs(true);
    const userOptions: string[] = this._aiAssistantStore.vm.userOptions();
    this._aiAssistantStore.setHasInteractedWithAssistant(true);
    const messageId: Id = this._aiAssistantStore.sendInternalSystemMessage(getChatActionActiveMessageKey('upload_file'), {
      isExecutingTool: true,
    });

    this._aiAssistantFileInputDialogService
      .openFileInputDialog({ studyset: this._aiAssistantStore.vm.currentStudyset(), source: 'attach_cta' })
      .pipe(
        tap((result: AiAssistantFileInputResult): void => {
          const isError = isAiAssistantFileInputError(result);
          const isMediaResult = isAiAssistantFileInputDocument(result) || isAiAssistantFileInputImage(result);
          const text = isMediaResult ? getChatActionDoneMessageKey('upload_file') : '';

          this._aiAssistantStore.patchMessage(messageId, {
            isError,
            iconName: isError ? 'app-icon-alert-triangle' : getChatActionIconName('upload_file'),
            isExecutingTool: false,
            text: isError ? 'AI_ASSISTANT.CHAT.SYSTEM.UPLOAD_FILE_ERROR' : text,
            userOptions: isNil(result) ? userOptions : [],
          });

          void this._aiAssistantStore.sendMediaAddedMessage(result);
        }),
        finalize(() => {
          this._aiAssistantStore.setIgnoreOpenDialogs(false);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Opens the premium overlay.
   */
  onOpenPremiumOverlay(): void {
    this._premiumModalService.openPremiumModal({ source: 'ai_assistant' });
  }
}
