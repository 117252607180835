import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { IllustrationDirective } from '@stsm/ui-components/disco-color/illustration.directive';

import { AnalyticsService } from '../../../shared/services/analytics.service';

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
  standalone: true,
  imports: [ButtonComponent, IllustrationDirective, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateDialogComponent {
  constructor(
    private readonly _dialogRef: DialogRef<UpdateDialogComponent>,
    private readonly _analyticsService: AnalyticsService,
  ) {
    this._analyticsService.trackEvent({ category: 'update_dialog', action: 'update_dialog_open' });
  }

  reload(): void {
    this._analyticsService.trackEvent({ category: 'update_dialog', action: 'update_dialog_reload' });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  close(): void {
    this._analyticsService.trackEvent({ category: 'update_dialog', action: 'update_dialog_close' });
    this._dialogRef.dismiss();
  }
}
