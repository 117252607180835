import { NgClass, NgTemplateOutlet } from '@angular/common';
import type { InputSignal, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, input, ViewChild } from '@angular/core';
import { IconFontName } from '@studysmarter/common-styles/lib/icon-font';

import { LatexRendererDirective } from '@stsm/shared/directives/latex-renderer';
import { MarkdownToHtmlPipe } from '@stsm/shared/pipes/markdown-to-html/markdown-to-html.pipe';
import { SpinnerComponent } from '@stsm/ui-components/spinner';

import type { AiAssistantMessage } from '../../models/conversation/message';
import { LOADING_MESSAGE_ID } from '../../models/conversation/message';
import { ResponseLoadingAnimationComponent } from '../response-loading-animation/response-loading-animation.component';

@Component({
  selector: 'app-chat-bubble',
  standalone: true,
  imports: [ResponseLoadingAnimationComponent, NgTemplateOutlet, LatexRendererDirective, SpinnerComponent, NgClass, MarkdownToHtmlPipe],
  templateUrl: './chat-bubble.component.html',
  styleUrl: './chat-bubble.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.is-user]': 'isUser',
    '[class.is-ai-assistant]': '!isUser && !isSystem',
    '[class.is-system]': 'isSystem',
    '[attr.data-cy]': 'isLoadingMessage ? "chat-bubble-loading" : "chat-bubble"',
  },
})
export class ChatBubbleComponent {
  @ViewChild(LatexRendererDirective) latexRenderer?: LatexRendererDirective;

  message: InputSignal<AiAssistantMessage> = input.required<AiAssistantMessage>();

  parsedMessage: Signal<string> = computed(() => {
    const { uiText, text, gptOnly } = this.message();

    const rawText = uiText ? uiText : gptOnly ? '' : text;

    return rawText ?? '';
  });

  showLoadingSpinner: Signal<boolean> = computed(() => this.message().role === 'system' && (this.message().isExecutingTool ?? false));

  iconName: Signal<IconFontName | undefined> = computed(() => this.message()?.iconName);

  get isUser(): boolean {
    return this.message().role === 'user';
  }

  get isSystem(): boolean {
    return this.message().role === 'system' || this.message().role === 'tool';
  }

  get isLoadingMessage(): boolean {
    return this.message().id === LOADING_MESSAGE_ID;
  }
}
