import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, UrlTree } from '@angular/router';
import { isNil } from 'lodash-es';
import { map, Observable, of, take } from 'rxjs';

import { AuthUrl } from '@stsm/auth/models/auth-url';
import { getDeepestActivatedRoute } from '@stsm/shared/util/router.util';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { IS_ROUTE_ALLOWED_FOR_ANONYMOUS_USER } from '../../shared/constants/is-route-allowed-for-anonymous-user-property';
import { SignupDialogService } from '../signup-dialog/signup-dialog.service';

export const isRouteAllowedForAnonymousUserGuard: CanActivateChildFn = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  const route = getDeepestActivatedRoute(activatedRouteSnapshot);

  // At this point we already know that there is a localUser in localStorage.
  const isRouteAllowedForAnonymousUser: boolean = coerceBooleanProperty(route.data[IS_ROUTE_ALLOWED_FOR_ANONYMOUS_USER]);

  if (isRouteAllowedForAnonymousUser) {
    return of(true);
  }

  const router = inject(Router);
  const userStoreFacade = inject(UserStoreFacade);
  const signupDialogService = inject(SignupDialogService);

  const previousNavigation = router.getCurrentNavigation()?.previousNavigation;

  return userStoreFacade.user$.pipe(
    take(1),
    map((user: User) => {
      // restarting the onboarding is handled elsewhere so we don't want to intervene with this guard
      if (!user.onboardingCompleted) {
        return true;
      }

      if (user.isAnonymous && isNil(previousNavigation)) {
        // The user has refreshed the app on a page that is not allowed for an anonymous user
        userStoreFacade.logout();

        return router.parseUrl(AuthUrl.GET_STARTED);
      }

      let spawnedBy: string | undefined;

      const firstUrlSegment = route.url[0];

      if (!isNil(firstUrlSegment)) {
        const path = firstUrlSegment.path;
        const { queryParams } = route;
        spawnedBy = path === 'search' && 'search' in queryParams ? 'search_keyword_enter' : 'left_sidebar_menu';
      }

      user.isAnonymous && signupDialogService.openSignupModal({ requestedUrl: route.url, spawnedBy });

      // Allow route for all non-anonymous users
      return !user.isAnonymous;
    }),
  );
};
