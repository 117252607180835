import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

export const StreakInfoAnimation = [
  trigger('fadeInOut', [
    state('inactive', style({ opacity: 0 })),
    state('active', style({ opacity: '{{ endOpacity }}' }), { params: { endOpacity: 1 } }),
    transition('inactive <=> active', animate('300ms linear')),
  ]),

  trigger('streakCarousel', [
    transition(':increment', [
      group([
        query('.old-streak', [
          style({ transform: 'translateY(0) translateX(-50%)', opacity: 0 }),
          animate('0ms', style({ opacity: 1 })),
          animate('400ms cubic-bezier(0.41, 1.29, 0.88, 1)', style({ transform: 'translateY(-100%) translateX(-50%)', opacity: 1 })),
        ]),

        query('.streak-number:not(.old-streak)', [
          style({ transform: 'translateY(100%)', opacity: 0 }),
          animate('400ms cubic-bezier(0.41, 1.29, 0.88, 1)', style({ transform: 'translateY(0)', opacity: 1 })),
        ]),
      ]),
    ]),
  ]),

  trigger('slideInOut', [
    state('visible', style({ height: '*', opacity: 1, overflow: 'hidden' })),
    state('hidden', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
    transition('visible => hidden', [animate('400ms 300ms cubic-bezier(0.41, 1.29, 0.88, 1)')]),
    transition('hidden => visible', [animate('400ms 300ms cubic-bezier(0.41, 1.29, 0.88, 1)')]),
  ]),
];
