import { Injectable } from '@angular/core';
import { noop } from 'lodash-es';
import { Observable, switchMap, tap } from 'rxjs';

import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { StreakDialogParams, StreakMessageService } from '@stsm/streak/services/streak-message.service';
import { StreakStore } from '@stsm/streak/services/streak-store';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { StreakInfoComponent } from '../components/streak-info/streak-info.component';

@Injectable({ providedIn: 'root' })
export class StreakDialogService {
  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _streakMessageService: StreakMessageService,
    private readonly _streakStore: StreakStore,
  ) {
    this._streakMessageService
      .onStreakDialogRequest()
      .pipe(switchMap((params: StreakDialogParams) => this._streakStore.fetchStreak().pipe(tap(() => this.show(params)))))
      .subscribe();
  }

  init(): void {
    noop();
  }

  show({ source }: StreakDialogParams): Observable<void> {
    return this._platformModalService.schedule<InputPropertiesOf<StreakInfoComponent>, void, StreakInfoComponent>({
      component: StreakInfoComponent,
      data: { source },
      disposeOnNavigation: false,
      webOptions: {
        width: 'min(480px, 80vw)',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
