import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  getLatestVersion(): Observable<Response> {
    return fromFetch('version.json');
  }
}
