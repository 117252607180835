import { Inject, Injectable, Signal } from '@angular/core';
import { patchState, SignalState, signalState } from '@ngrx/signals';
import { distinctUntilChanged, switchMap, take, tap } from 'rxjs';

import { ONBOARDING_TASKS_SERVICE, OnboardingTasksBaseService } from '@stsm/feed';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

interface AssistantState {
  isVisible: boolean;
}

const initialState: AssistantState = {
  isVisible: false,
};

@Injectable({ providedIn: 'root' })
export class AssistantStore {
  readonly isVisible: Signal<boolean>;

  private readonly _state: SignalState<AssistantState> = signalState<AssistantState>(initialState);

  constructor(
    @Inject(ONBOARDING_TASKS_SERVICE) private readonly _onboardingTasksService: OnboardingTasksBaseService,
    private readonly _userStoreFacade: UserStoreFacade,
  ) {
    this.isVisible = this._state.isVisible;

    if (!this._onboardingTasksService.isEnabled()) {
      return;
    }

    this._userStoreFacade.user$
      .pipe(
        distinctUntilChanged((previous: User, current: User) => {
          return previous.id === current.id && previous.isAnonymous === current.isAnonymous;
        }),
        switchMap((user: User) => {
          return this._onboardingTasksService.onboardingTasksCompleted$.pipe(
            take(1),
            tap((areOnboardingTasksCompleted: boolean) => {
              this.setVisibility(!user.isAnonymous && !areOnboardingTasksCompleted);
            }),
          );
        }),
      )
      .subscribe();
  }

  setVisibility(isVisible: boolean): void {
    patchState(this._state, { isVisible });
  }
}
