import { ChangeDetectionStrategy, Component } from '@angular/core';

import { OnboardingTaskGroupComponent } from '@stsm/feed';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

@Component({
  selector: 'app-assistant-dialog',
  standalone: true,
  templateUrl: './assistant-dialog.component.html',
  styleUrls: ['./assistant-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, DialogTemplateComponent, OnboardingTaskGroupComponent, TranslatePipe],
  host: { ['data-cy']: 'assistant-dialog' },
})
export class AssistantDialogComponent {
  constructor(private readonly _dialogRef: DialogRef<AssistantDialogComponent>) {}

  protected close(): void {
    return this._dialogRef.dismiss();
  }
}
