<div class="avatar" [@avatar]="animationState">
  <div uiAnimatedIllustration name="ai-sparkles-white" [isActive]="isAnimatingAvatar"></div>
</div>

<div class="content" [@topBarContent]="animationState">
  <div class="title">
    @if (showNotification) {
      <span class="one-liner" data-cy="ai-assistant-notification-heading">{{ pillMessage?.heading ?? "" | translate }}</span>
    } @else {
      <span>{{ "AI_ASSISTANT.CHAT.TITLE" | translate }}</span>
      <ui-beta-badge />
    }
  </div>
  <div class="supporting-text one-liner" [attr.data-cy]="showNotification ? 'ai-assistant-notification-content' : null">
    @if (showNotification) {
      {{ pillMessage?.text ?? "" | translate }}
    } @else {
      {{ (isGeneratingResponse ? "AI_ASSISTANT.CHAT.STATUS.THINKING" : "AI_ASSISTANT.CHAT.STATUS.ACTIVE") | translate }}
    }
  </div>
</div>

<div class="actions">
  <button
    *ngIf="!showNotification"
    uiIconButton
    tertiary
    size="xs"
    [@topBarContent]="animationState"
    [disabled]="isRefreshDisabled"
    (click)="resetAssistant.emit()"
  >
    <i class="app-icon-refresh"></i>
  </button>
  <button uiIconButton tertiary size="xs" data-cy="ai-assistant-close-button" (click)="$event.stopPropagation(); closeAssistant.emit()">
    <i
      [class.app-icon-close]="animationState === 'pill'"
      [class.app-icon-chevron-down]="animationState === 'chat' || animationState === 'chat-mobile'"
    ></i>
  </button>
</div>
