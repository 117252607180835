import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { EnvironmentProviders, ErrorHandler, Provider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AdLinkOpeningWebService } from '@stsm/advertisement/services/ad-link-opening-web.service';
import { AD_LINK_OPENING_SERVICE } from '@stsm/advertisement/tokens/ad-link-opening-service.token';
import { USER_SEGMENTATION_EVENTS_WEB_PROVIDER } from '@stsm/advertisement/user-segmentation/user-segmentation-events.token';
import { ANALYTICS_SERVICE } from '@stsm/analytics';
import { CONTEXTUAL_TUTORIAL_SERVICE } from '@stsm/contextual-tutorial';
import { recaptchaV3SiteKeyFactory } from '@stsm/devtools/utils/devtools-util';
import { ONBOARDING_TASKS_SERVICE, SURVEY_SERVICE } from '@stsm/feed';
import { FlashcardsAdService } from '@stsm/flashcards/feature/services/flashcards-ad.service';
import { FLASHCARD_DATA_STORE } from '@stsm/flashcards/feature/tokens/flashcard-data-store.token';
import { FLASHCARD_TAGS_DIALOG_SERVICE } from '@stsm/flashcards/feature/tokens/flashcard-tags-dialog-service.token';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { NotificationsService } from '@stsm/global/composite/services/notifications.service';
import { TitleService } from '@stsm/global/composite/services/title.service';
import { FEATURE_TIME_TRACKING_SERVICE } from '@stsm/global/composite/tokens/feature-time-tracking-service.token';
import { FEEDBACK_SERVICE } from '@stsm/global/composite/tokens/feedback-service.token';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { NOTIFICATIONS_SERVICE } from '@stsm/global/composite/tokens/notifications-service.token';
import { SHARING_SERVICE } from '@stsm/global/composite/tokens/sharing-service.token';
import { ONBOARDING_STORE } from '@stsm/onboarding/control/onboarding-store.token';
import { PREMIUM_SERVICE } from '@stsm/premium/tokes/premium-base-service.token';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { PREMIUM_PLANS_SERVICE } from '@stsm/premium/tokes/premium-plans-service.token';
import { PURCHASE_SERVICE } from '@stsm/premium/tokes/purchase-service.token';
import { PLATFORM_NAVIGATION_ROUTES } from '@stsm/scaffold/tokens/navigation-routes.token';
import { LOGGER_CONFIG } from '@stsm/shared/logger/logger-config.token';
import { WINDOW_PROVIDER } from '@stsm/shared/providers/window.provider';
import { BROWSER_STORAGES_PROVIDER } from '@stsm/shared/services/browser-storage/browser-storages.provider';
import { APP_VERSION } from '@stsm/shared/tokens/app-version.token';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';
import { SHARE_STUDYSET_SERVICE } from '@stsm/studysets/feature/tokens/share-studyset-service.token';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { TAGS_SERVICE } from '@stsm/tags/services/tokens/tags-service.token';
import { USER_SERVICE } from '@stsm/user/feature/tokens/user-service.token';

import { environment } from '../environments/environment';

import { NAVIGATION_ROUTES } from './app.routes';
import { AppSettings } from './app-settings';
import { PaymentDialogService } from './components/payment-dialog/payment-dialog.service';
import { ContextualTutorialService } from './contextual-tutorials/services/contextual-tutorial.service';
import { CustomErrorHandler } from './error-handler';
import { OnboardingTasksService } from './feed/services/onboarding-tasks.service';
import { SurveyService } from './feed/services/survey.service';
import { FlashcardDataStore } from './flashcards/flashcard-data-store.service';
import { FlashcardTagsDialogService } from './flashcards/flashcard-tags-dialog/flashcard-tags-dialog.service';
import { FlashcardsService } from './flashcards/flashcards.service';
import { HTTP_INTERCEPTOR_PROVIDERS } from './http-interceptors';
import { OnboardingStore } from './onboarding/onboarding-store.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { FeatureTimeTrackingService } from './shared/services/feature-time-tracking.service';
import { FeedbackService } from './shared/services/feedback.service';
import { NavigationService } from './shared/services/navigation.service';
import { PlatformService } from './shared/services/platform.service';
import { PremiumService } from './shared/services/premium.service';
import { PremiumModalWebService } from './shared/services/premium-modal-web.service';
import { PremiumPlansWebService } from './shared/services/premium-plans-web.service';
import { ShareStudysetService } from './shared/services/share-studyset.service';
import { SharingService } from './shared/services/sharing.service';
import { StudysetsService } from './shared/services/studysets.service';
import { TagsService } from './shared/services/tags.service';
import { UserService } from './shared/services/user.service';

export function getAppProviders(): (Provider | EnvironmentProviders)[] {
  return [
    { provide: LOGGER_CONFIG, useValue: { loggerLevel: environment.LOGGER.LEVEL } },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: ErrorHandler, useClass: environment.SENTRY_DSN ? CustomErrorHandler : ErrorHandler },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: () => recaptchaV3SiteKeyFactory(environment.production, environment.RECAPTCHA_SITE_KEY),
    },
    { provide: ANALYTICS_SERVICE, useExisting: AnalyticsService },
    { provide: FEATURE_TIME_TRACKING_SERVICE, useExisting: FeatureTimeTrackingService },
    { provide: USER_SERVICE, useExisting: UserService },
    { provide: ONBOARDING_STORE, useExisting: OnboardingStore },
    { provide: NAVIGATION_SERVICE, useExisting: NavigationService },
    { provide: STUDYSETS_SERVICE, useExisting: StudysetsService },
    { provide: FLASHCARDS_SERVICE, useExisting: FlashcardsService },
    { provide: AD_LINK_OPENING_SERVICE, useExisting: AdLinkOpeningWebService },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: PLATFORM_SERVICE, useExisting: PlatformService },
    { provide: CONTEXTUAL_TUTORIAL_SERVICE, useExisting: ContextualTutorialService },
    { provide: PREMIUM_MODAL_SERVICE, useExisting: PremiumModalWebService },
    { provide: PREMIUM_PLANS_SERVICE, useExisting: PremiumPlansWebService },
    { provide: PREMIUM_SERVICE, useExisting: PremiumService },
    { provide: PURCHASE_SERVICE, useExisting: PaymentDialogService },
    { provide: APP_VERSION, useValue: AppSettings.VERSION_NUMBER },
    { provide: IS_MOBILE_APP, useValue: false },
    { provide: SHARING_SERVICE, useExisting: SharingService },
    { provide: FEEDBACK_SERVICE, useExisting: FeedbackService },
    { provide: ONBOARDING_TASKS_SERVICE, useExisting: OnboardingTasksService },
    { provide: PLATFORM_NAVIGATION_ROUTES, useValue: NAVIGATION_ROUTES },
    { provide: SURVEY_SERVICE, useExisting: SurveyService },
    { provide: TAGS_SERVICE, useExisting: TagsService },
    { provide: FLASHCARD_DATA_STORE, useExisting: FlashcardDataStore },
    { provide: FLASHCARD_TAGS_DIALOG_SERVICE, useExisting: FlashcardTagsDialogService },
    { provide: SHARE_STUDYSET_SERVICE, useExisting: ShareStudysetService },
    { provide: NOTIFICATIONS_SERVICE, useExisting: NotificationsService },
    { provide: TitleStrategy, useExisting: TitleService },
    BROWSER_STORAGES_PROVIDER,
    HTTP_INTERCEPTOR_PROVIDERS,
    USER_SEGMENTATION_EVENTS_WEB_PROVIDER,
    WINDOW_PROVIDER,

    /**
     * This is currently a bad solution. The FlashcardDataStore is provided in root and needs the FlashcardsAdService.
     * Usually the FlashcardsAdService should be local to the flashcard trainer page / spaced repetition / quiz.
     * For web, we need to split the data storage / fetching layer and the store that handles the practice mode, but
     * this is currently not the case.
     *
     * For web, we thus have one global instance and local instances for Quiz and Spaced Repetition
     */
    FlashcardsAdService,
  ];
}
