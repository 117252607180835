<ng-container *ngIf="ad">
  <header>
    <button uiIconButton tertiary data-cy="lead-gen-ad-modal-previous-button" [class.hide]="isFirstStep" (click)="previous()">
      <i class="app-icon-chevron-back" slot="icon-only"></i>
    </button>

    <span class="one-liner" data-cy="lead-gen-ad-modal-header">{{ ad.companyName }}</span>

    <button uiIconButton tertiary class="dismiss-button" data-cy="lead-gen-ad-modal-dismiss-button" (click)="dismiss()">
      <i class="app-icon-close" slot="icon-only"></i>
    </button>
  </header>
  <main>
    <ui-stepper hideProgressText data-cy="lead-gen-ad-modal-ui-stepper" [hideProgressBar]="stepsCount === 1">
      <ui-step *ngIf="ad.description && !shouldSkipAdDisplay">
        <div class="ad-info-step">
          <app-enigma-media [ad]="ad" [preferredMediaOrientation]="mediaOrientation" />

          <ng-container [ngTemplateOutlet]="adInfo" />
        </div>
      </ui-step>

      @for (customQuestion of ad.leadQuestions; track customQuestion.id) {
        <ui-step>
          <app-lead-gen-ad-modal-lead-question [question]="customQuestion" (setAnswers)="setLeadQuestionAnswers(customQuestion, $event)" />
        </ui-step>
      }

      <ui-step>
        <ng-container [ngTemplateOutlet]="userInfo" />
      </ui-step>
    </ui-stepper>
  </main>

  <footer>
    @if (isLastStep) {
      <button uiButton data-cy="lead-gen-ad-modal-submit-button" [disabled]="!leadGenForm.valid" (click)="submit()">
        {{ "LEAD_GEN_ADS.SUBMIT_BUTTON" | translate }}
      </button>
    } @else {
      <button uiButton data-cy="lead-gen-ad-modal-next-button" [disabled]="isNextButtonDisabled" (click)="next()">
        {{ "GLOBAL.NEXT" | translate }}
      </button>
    }
  </footer>

  <ng-template #adInfo>
    <h4 class="title">
      <img class="logo" alt="Logo" height="30" role="presentation" [src]="ad.companyLogoUrl" />
      <span data-cy="lead-gen-ad-modal-tagline">{{ ad.leadGenAdTagline }}</span>
    </h4>

    <p *ngIf="ad.description" class="description" data-cy="lead-gen-ad-modal-description" [innerHTML]="ad.description"></p>
  </ng-template>

  <ng-template #userInfo>
    <div class="user-info-step">
      <ng-container *ngIf="!ad.description; then adInfo" />

      <div *ngIf="stepsCount > 1" class="banner">
        <img class="success-img" uiIllustration="positive-vote" alt="" />
        <p>
          <span class="banner-title">{{ "LEAD_GEN_ADS.BANNER.TITLE" | translate }}</span>

          <span class="banner-text"> {{ "LEAD_GEN_ADS.BANNER.TEXT" | translate }} </span>
        </p>
      </div>

      <form data-cy="lead-gen-ad-modal-form" [formGroup]="leadGenForm">
        <ui-form-field>
          <label uiLabel>{{ "LEAD_GEN_ADS.FIRST_NAME_INPUT_LABEL" | translate }}</label>
          <input
            uiInput
            type="text"
            formControlName="firstName"
            data-cy="lead-gen-ad-modal-first-name-input"
            [placeholder]="'LEAD_GEN_ADS.FIRST_NAME_INPUT_LABEL' | translate"
          />

          <ui-form-error *ngIf="leadGenForm.controls.firstName.errors?.['required']" data-cy="lead-gen-ad-modal-first-name-empty-error">
            {{ "LEAD_GEN_ADS.FIRST_NAME_INPUT_EMPTY_MESSAGE" | translate }}
          </ui-form-error>
        </ui-form-field>

        <ui-form-field>
          <label uiLabel>{{ "LEAD_GEN_ADS.LAST_NAME_INPUT_LABEL" | translate }}</label>
          <input
            uiInput
            type="text"
            formControlName="lastName"
            data-cy="lead-gen-ad-modal-last-name-input"
            [placeholder]="'LEAD_GEN_ADS.LAST_NAME_INPUT_LABEL' | translate"
          />

          <ui-form-error *ngIf="leadGenForm.controls.lastName.errors?.['required']" data-cy="lead-gen-ad-modal-last-name-empty-error">
            {{ "LEAD_GEN_ADS.LAST_NAME_INPUT_EMPTY_MESSAGE" | translate }}
          </ui-form-error>
        </ui-form-field>

        <ui-form-field>
          <label uiLabel>{{ "LEAD_GEN_ADS.EMAIL_INPUT_LABEL" | translate }}</label>

          <input
            uiInput
            type="email"
            formControlName="email"
            data-cy="lead-gen-ad-modal-email-input"
            [placeholder]="'LEAD_GEN_ADS.EMAIL_INPUT_LABEL' | translate"
          />
        </ui-form-field>

        <ui-form-field>
          <label uiLabel>{{ "LEAD_GEN_ADS.PHONE_INPUT_LABEL" | translate }}</label>

          <ui-phone-number-input
            formControlName="phoneNumber"
            [placeholder]="'LEAD_GEN_ADS.PHONE_INPUT_LABEL' | translate"
            [userCallingCode]="user.countryCallingCode"
          />
        </ui-form-field>
        <ui-phone-number-input-errors [errors]="leadGenForm.controls.phoneNumber.errors" />

        <section class="privacy-notice">
          <h6 class="label">{{ "LEAD_GEN_ADS.PRIVACY_NOTICE_LABEL" | translate }}</h6>

          <p class="text" [innerHTML]="'LEAD_GEN_ADS.PRIVACY_NOTICE_TEXT' | translate: { companyName: ad.companyName }"></p>
        </section>
      </form>
    </div>
  </ng-template>
</ng-container>
