<div class="premium-banner-content">
  <div class="premium-banner-content-header">
    <i class="app-icon-logo" aria-hidden="true"></i>

    <h5 class="heading">
      {{ "AI_ASSISTANT.CHAT.COUNTER.PREMIUM_BANNER_HEADER" | translate }}
      <span data-testid="countdown">{{ countdown$ | async }}</span>
    </h5>
  </div>

  @if (usedFreeTrial()) {
    <p data-testid="used-free-trial-banner">{{ "AI_ASSISTANT.CHAT.COUNTER.USED_FREE_TRIAL_PREMIUM_BANNER_TEXT" | translate }}</p>
  } @else {
    <p>{{ "AI_ASSISTANT.CHAT.COUNTER.PREMIUM_BANNER_TEXT" | translate }}</p>
  }
</div>

<i class="app-icon-chevron-forward arrow" aria-hidden="true" (click)="openModalClicked.emit()"></i>
