import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, input, InputSignal, Output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable, switchMap } from 'rxjs';

import { createSafeDate } from '@stsm/date/functions/safe-date';
import { getCountdownString } from '@stsm/date/util/countdown-util';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';

@Component({
  selector: 'app-premium-countdown-banner',
  standalone: true,
  imports: [AsyncPipe, TranslatePipe],
  templateUrl: './premium-countdown-banner.component.html',
  styleUrl: './premium-countdown-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-test-id': 'premium-countdown-banner',
  },
})
export class PremiumCountdownBannerComponent {
  readonly usedFreeTrial: InputSignal<boolean> = input.required<boolean>();
  readonly resetDate: InputSignal<string> = input.required<string>();

  @Output() readonly openModalClicked: EventEmitter<void> = new EventEmitter<void>();

  protected readonly countdown$: Observable<string> = toObservable(this.resetDate).pipe(
    switchMap((resetDate: string) => getCountdownString(createSafeDate(resetDate))),
  );
}
