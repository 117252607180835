import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  EMPTY,
  filter,
  firstValueFrom,
  merge,
  Observable,
  pairwise,
  skip,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import { taskTrackingSources } from '@stsm/analytics';
import { CONTEXTUAL_TUTORIAL_SERVICE, ContextualTutorialBaseService } from '@stsm/contextual-tutorial';
import { ONBOARDING_TASKS_SERVICE, OnboardingTasksBaseService } from '@stsm/feed';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { Tab } from '@stsm/shared/enums/tab';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { latestFrom, switchToVoid, VOID } from '@stsm/shared/util/rxjs.util';
import { ButtonComponent } from '@stsm/ui-components/button';
import { ConfettiCanvasComponent } from '@stsm/ui-components/confetti-canvas';

import { AssistantStore } from '../../services/assistant-store.service';
import { AssistantDialogService } from '../assistant-dialog/assistant-dialog.service';
import { FirstStepsCompletedDialogService } from '../first-steps-completed-dialog/first-steps-completed-dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-assistant-button',
  standalone: true,
  templateUrl: './assistant-button.component.html',
  styleUrls: ['./assistant-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, ConfettiCanvasComponent],
})
export class AssistantButtonComponent {
  private readonly _dialogOpenedAtSessionStart$: Subject<void> = new Subject<void>();
  private _areOnboardingTasksCompleted: boolean = false;

  constructor(
    @Inject(IS_MOBILE_APP) private readonly _isMobileApp: boolean,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    @Inject(ONBOARDING_TASKS_SERVICE) private readonly _onboardingTasksService: OnboardingTasksBaseService,
    @Inject(CONTEXTUAL_TUTORIAL_SERVICE) private readonly _contextualTutorialService: ContextualTutorialBaseService,
    private readonly _assistantStore: AssistantStore,
    private readonly _assistantDialogService: AssistantDialogService,
    private readonly _elementRef: ElementRef,
    private readonly _firstStepsCompletedDialogService: FirstStepsCompletedDialogService,
    private readonly _layoutStore: LayoutStore,
    private readonly _routerStoreFacade: RouterStoreFacade,
  ) {
    this._onboardingTasksService.onboardingTasksCompleted$.pipe(untilDestroyed(this)).subscribe((areOnboardingTasksCompleted: boolean) => {
      this._areOnboardingTasksCompleted = areOnboardingTasksCompleted;
    });

    this._setupCommonBehaviour();

    if (!this._isMobileApp) {
      this._setupWebBehaviour();
    }
  }

  protected openAssistant(): void {
    if (this._assistantDialogService.isOpen()) {
      return;
    }

    if (this._areOnboardingTasksCompleted) {
      this._assistantStore.setVisibility(false);

      return;
    }

    void firstValueFrom(this._openDialog('assistant'));
  }

  private _openDialog(type: 'assistant' | 'completion'): Observable<void> {
    if (this._contextualTutorialService.isTutorialRunning()) {
      return VOID;
    }

    return type === 'assistant'
      ? this._assistantDialogService.openDialog(this._elementRef)
      : this._firstStepsCompletedDialogService.openDialog().pipe(
          switchMap(() => this._navigationService.navigateToTab(Tab.HOME)),
          tap(() => this._assistantStore.setVisibility(false)),
          switchToVoid(),
        );
  }

  /**
   * Onboarding Assistant Common Behaviour
   * - open after each manually completed task
   */
  private _setupCommonBehaviour(): void {
    this._onboardingTasksService.taskCompleted$
      .pipe(
        debounceTime(1000),
        latestFrom(this._onboardingTasksService.onboardingTasksCompleted$),
        switchMap((areOnboardingTasksCompleted: boolean) => {
          if (areOnboardingTasksCompleted) {
            return this._openDialog('completion');
          }

          return this._layoutStore.isFullPageRouteActive$.pipe(
            filter((isFullPageRouteActive: boolean) => !isFullPageRouteActive),
            take(1),
            tap(() => this.openAssistant()),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Onboarding Assistant Web Behaviour
   * - open on session start unless user is on home page or navigates immediately
   * - open when leaving the home page unless it was opened at session start
   */
  private _setupWebBehaviour(): void {
    this._routerStoreFacade.url$
      .pipe(
        switchMap((url: string) => {
          if (url.includes(Tab.HOME)) {
            return EMPTY;
          }

          return this._layoutStore.isFullPageRouteActive$.pipe(
            switchMap((isFullPageRouteActive: boolean) => {
              if (isFullPageRouteActive) {
                return EMPTY;
              }

              return timer(2000); // provide timely experience without abruptness + consider layout shifts
            }),
          );
        }),
      )
      .pipe(takeUntil(this._routerStoreFacade.url$.pipe(skip(1))))
      .subscribe(() => {
        this.openAssistant();
        this._dialogOpenedAtSessionStart$.next();
        this._dialogOpenedAtSessionStart$.complete();
      });

    this._routerStoreFacade.url$
      .pipe(
        pairwise(),
        filter(([previous, current]: [string, string]) => {
          const isLeavingHomePage = previous.includes(Tab.HOME) && !current.includes(Tab.HOME);
          const isNavigationCausedByTask = this._isNavigationCausedByTask(current);

          return isLeavingHomePage && !isNavigationCausedByTask;
        }),
        take(1),
        takeUntil(merge(this._dialogOpenedAtSessionStart$, this._onboardingTasksService.taskCompleted$)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.openAssistant();
      });
  }

  private _isNavigationCausedByTask(url: string): boolean {
    if (taskTrackingSources.length) {
      return new RegExp(taskTrackingSources.join('|'), 'gi').test(url);
    }

    return false;
  }
}
