import { Injectable } from '@angular/core';
import { combineLatest, Observable, switchMap, take } from 'rxjs';

import { AuthStore } from '@stsm/auth/data/auth-store.service';
import {
  FeedbackBaseService,
  FeedbackOptions,
  FeedbackPayload,
  FeedbackType,
  ReportOptions,
} from '@stsm/global/composite/services/feedback-base.service';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { BaseService } from '@stsm/shared/services/base.service';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app-settings';

import { AnalyticsService } from './analytics.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService extends FeedbackBaseService {
  constructor(
    private readonly _baseService: BaseService,
    private readonly _authStore: AuthStore,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _analyticsService: AnalyticsService,
    private readonly _translationService: TranslationService,
    private readonly _platformService: PlatformService,
  ) {
    super();
  }

  sendReport({
    feedbackType,
    message,
    contentType,
    contentId,
    studysetId,
    reportCategory,
    secondaryReportCategory,
    isPrivate,
  }: ReportOptions): Observable<void> {
    return combineLatest([this._userStoreFacade.premiumInfo$, this._userStoreFacade.isPupil$]).pipe(
      take(1),
      switchMap(([premiumInfo]: [PremiumInfo, boolean]) => {
        const device = this._platformService.browser;
        const subject =
          `Content Report ` + (premiumInfo.isPremium ? `[PREMIUM-User ${this._authStore.userId}] ` : `[User ${this._authStore.userId}]`);

        const body: FeedbackPayload = {
          subject,
          message,
          mobile: false,
          custom_fields: this.connectCustomFieldsToFeedbackPayload({
            appVersion: AppSettings.VERSION_NUMBER,
            feedbackType: this.getFeedbackTypeString('Content Report'),
            language: this._translationService.currentLanguage().toUpperCase(),
            deviceEnvironment: `[Device: ${this._platformService.getCurrentPlatform()} | ${device}`,
            ...(feedbackType === FeedbackType.CONTENT_REPORT
              ? {
                  contentId,
                  contentType,
                  studysetId,
                  reportCategory: secondaryReportCategory || reportCategory,
                  isPrivate,
                  contentLink: `${environment.WEBAPP_URL}/studysets/${studysetId}`,
                }
              : {}),
            ...(feedbackType === FeedbackType.IMPORT_FLASHCARD_REPORT ? { reportCategory: 'import flashcard' } : {}),
          }),
        };

        return this._baseService.post(`feedback/`, body);
      }),
      switchMap(() => {
        this._analyticsService.trackEvent({
          category: 'app_report',
          action: 'report_content',
          properties: {
            content_type: contentType,
            content_id: contentId,
            study_set: studysetId,
            report_category: secondaryReportCategory || reportCategory,
          },
        });

        return VOID;
      }),
    );
  }

  sendFeedback(options: FeedbackOptions): Observable<void> {
    return combineLatest([this._userStoreFacade.premiumInfo$, this._userStoreFacade.isPupil$]).pipe(
      take(1),
      switchMap(([premiumInfo, isPupil]: [PremiumInfo, boolean]): Observable<void> => {
        const device = this._platformService.browser;

        const body: FeedbackPayload = this.getFeedbackPayload({
          options,
          premiumInfo,
          isPupil,
          deviceEnvironment: `[Device: ${this._platformService.getCurrentPlatform()} | ${device}`,
          appVersion: 'Web App',
          feedbackSource: options?.feedbackSource ?? 'Feedback',
        });

        return this._baseService.post(`feedback/`, body);
      }),
    );
  }

  requestAppStoreRating(): void {
    return;
  }
}
