<img src="assets/shared/svgs/assistant.svg" height="64" width="64" alt="" />

<h3>{{ "ASSISTANT.FIRST_STEPS_COMPLETED_DIALOG.HEADING" | translate }}</h3>

<p>{{ "ASSISTANT.FIRST_STEPS_COMPLETED_DIALOG.TEXT" | translate }}</p>

<button uiButton (click)="close()">
  {{ "ASSISTANT.FIRST_STEPS_COMPLETED_DIALOG.BUTTON_LABEL" | translate }}
</button>

<ui-confetti-canvas />
