<button uiIconButton secondary size="xs" class="close-button" data-cy="streak-info-close-button" (click)="closeDialog()">
  <i class="app-icon-close"></i>
</button>

<div class="streak-infos">
  <div class="streak-display-container">
    <img alt="" class="grey-fire" [src]="StreakIcon.FLAME_GRAY" [@fadeInOut]="isInactive() ? 'active' : 'inactive'" />
    <div
      class="animation-fire-small"
      uiAnimatedEmote
      name="fire"
      [@fadeInOut]="{ value: isInactive() ? 'inactive' : 'active', params: { endOpacity: 0.2 } }"
    ></div>

    <div class="streak-number-container" [@streakCarousel]="streakNumberAnimation()">
      <!-- 
        This span element is visible just during the animation. 
        We show the previous streak (current streak as already been incremented) and then transition to the current streak. 
        Therefore, we will never see -1 because for the animation to trigger we need at least a streak of 1. 
      -->
      <span class="streak-number old-streak">{{ streakNumberAnimation() - 1 }}</span>
      <span class="streak-number" data-cy="streak-info-current-streak">
        {{ streakNumberAnimation() }}
      </span>
    </div>
    <div class="animation-fire-big" uiAnimatedEmote name="fire" [@fadeInOut]="isInactive() ? 'inactive' : 'active'"></div>
  </div>
  <div class="streak-description">{{ "STREAK.DAY_STREAK" | translate }}</div>
  <div class="motivational-message">{{ motivationalMessage() | translate }}</div>
</div>

@if (isTreatment()) {
  <div class="calendar-infos-container">
    <div *ngrxLet="streak().weeklyStreak as weeklyStreak" class="calendar">
      @if (weeklyStreak) {
        <app-streak-calendar [weeklyStreak]="weeklyStreak" />
      }
    </div>

    <div [@slideInOut]="messageAnimationState() === 'final' ? 'visible' : 'hidden'">
      <div
        *ngrxLet="{
          streakStatusToday: streakStatusToday$,
          streakStatusTomorrow: streakStatusTomorrow$,
          nextTask: nextTask$,
        } as vm"
        class="info-phrase"
      >
        @switch (vm.streakStatusToday) {
          @case (StreakDayStatus.OPEN) {
            @if (vm.nextTask) {
              <span>{{ vm.nextTask.title | translate }}</span>

              <button uiButton size="lg" class="task-button" data-cy="streak-info-action-button" (click)="performTask(vm.nextTask)">
                {{ streakActionButtonText() | translate }}
              </button>
            }
          }

          @case (StreakDayStatus.ACHIEVED) {
            @if (vm.streakStatusTomorrow === StreakDayStatus.OPEN) {
              <span>{{ "STREAK.INFO.TOMORROW_OPEN" | translate }}</span>
            }
            @if (vm.streakStatusTomorrow === StreakDayStatus.IDLE) {
              <span>{{ "STREAK.INFO.TOMORROW_IDLE" | translate }}</span>
            }
          }

          @case (StreakDayStatus.IDLE) {
            @if (vm.streakStatusTomorrow === StreakDayStatus.OPEN) {
              <span>{{ "STREAK.INFO.TOMORROW_OPEN" | translate }}</span>
            }
            @if (vm.streakStatusTomorrow === StreakDayStatus.IDLE) {
              <span>{{ "STREAK.INFO.TODAY_IDLE" | translate }}</span>
            }
          }
        }
      </div>
    </div>
  </div>
}

@if (!isLearningPlanEnabledOnOpen() && isMobileApp) {
  <div *ngrxLet="{ notificationsEnabled: notificationsEnabled$, user: user$ } as vm" class="study-reminders">
    <app-studyplan-card
      eventSource="streak"
      [notificationsEnabled]="vm.notificationsEnabled"
      [studyplanEnabled]="vm.user.settings.learningPlanEnabled"
      [notificationTimeSlotValue]="vm.user.notificationTimeSlot"
    />
  </div>
}

@if (!isTreatment() && !isMobileLayout()) {
  <div class="button-wrapper">
    <button uiButton class="bottom-close-button" size="lg" (click)="closeDialog()">
      {{ "GLOBAL.CLOSE" | translate }}
    </button>
  </div>
}
