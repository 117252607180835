@if (showBottomSheetToggle() && showChatContent) {
  <button class="bottom-sheet-toggle" @fadeInOut (touchstart)="toggleChatHeight($event)" (click)="toggleChatHeight($event)">
    <span class="toggle-bar"></span>
  </button>
}

<app-chat-top-row
  [animationState]="vm.animationState()"
  [isGeneratingResponse]="vm.isChatOpen() && vm.isGeneratingResponse()"
  [isAnimatingAvatar]="vm.isProcessing()"
  [showNotification]="!vm.isChatOpen()"
  [pillMessage]="vm.pillMessage()"
  [isRefreshDisabled]="premiumVm.messageLimitReached()"
  (resetAssistant)="reset()"
  (closeAssistant)="closeAssistant.emit()"
  (click)="openAssistant()"
/>

@if (showChatContent) {
  <main #scrollContainer [@fadeUp] (@fadeUp.done)="scrollContainer.classList.add('animation-done')" (scroll)="onScroll($event)">
    <app-chat-conversation [messages]="allMessages()" [isGeneratingResponse]="vm.isGeneratingResponse()" />
  </main>

  <footer [@fadeUp]>
    @if (showScrollToBottomButton()) {
      <button class="scroll-down-button" uiIconButton size="md" secondary [@fadeInOutScale] (click)="scrollToBottom()">
        <i class="app-icon-arrow-down"></i>
      </button>
    }

    @if (vm.userOptions().length > 0 && !premiumVm.messageLimitReached()) {
      <div class="user-options">
        @for (option of vm.userOptions(); track option) {
          <button
            uiButton
            secondary
            size="sm"
            data-cy="ai-assistant-user-option"
            [@fadeInOut]
            [disabled]="premiumVm.messageLimitReached()"
            (click)="onOptionClick(option)"
          >
            <span>{{ option }}</span>
          </button>
        }
      </div>
    }

    @if (premiumVm.showPremiumBanner()) {
      <app-premium-countdown-banner
        [resetDate]="premiumVm.resetDate()"
        [usedFreeTrial]="premiumVm.usedFreeTrial()"
        (openModalClicked)="onOpenPremiumOverlay()"
      />
    }

    <div class="input-container">
      @if (vm.enableMediaInput()) {
        <button
          uiIconButton
          data-cy="ai-assistant-media-input-button"
          secondary
          size="lg"
          [id]="mediaTooltipElementId"
          [disabled]="vm.isProcessing() || premiumVm.messageLimitReached()"
          (click)="addFile()"
        >
          <ui-dot-badge *ngIf="vm.showNotificationBadge()" notificationBadge [badgeValue]="true" />
          <i class="app-icon-attachment"></i>
        </button>
      }

      <ui-text-submit-input
        isCloseDisabled
        isChatMode
        data-cy="ai-assistant-input"
        [showCounter]="premiumVm.showMessageCounter()"
        [isDisabled]="premiumVm.shouldDisableTextSubmitInput() || vm.isProcessing()"
        [placeholderKey]="vm.inputPlaceholder()"
        [autofocus]="!isMobileApp"
        (confirm)="sendMessage($event)"
      >
        @if (premiumVm.showMessageCounter()) {
          <ng-container slot="ui-counter">
            <ui-counter
              [ariaLabel]="'AI_ASSISTANT.CHAT.COUNTER_ARIA-LABEL' | translate"
              [count]="premiumVm.messageCount()"
              [maxCount]="premiumVm.messageLimit()"
            />
          </ng-container>
        }
      </ui-text-submit-input>
    </div>
  </footer>

  <ui-confetti-canvas [showFullscreen]="false" />
}
