import type { AnimationMetadata, AnimationQueryMetadata } from '@angular/animations';
import {
  animate,
  animateChild,
  AnimationQueryOptions,
  AnimationTriggerMetadata,
  group,
  keyframes,
  query,
  sequence,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const defaultTransition = '.3s ease';
const gradientBorderWidth = '2px';
const buttonDimensions = '52px';
const buttonContainerDimensions = '56px'; // buttonDimensions + 2 * gradientBorderWidth
const chatPillDimensions = '40px';
const containerBorderRadius = '28px'; // a fixed value is required for a smooth transition
const pillBorderRadius = '56px'; // a fixed value is required for a smooth transition

/**
 * The padding of the chat pill is also used as margin for the button
 * so that during the animation the avatar does not move and the animation looks smooth.
 * Because of the margin, the bottom and left positioning of the container needs to be reduced by the padding
 * to still have the same distance from the FAB button to the screen edges.
 */
const pillPadding = 'var(--spacing-sm)';

/**
 * A util to avoid repeating the same query structure
 */
const createQuery = (
  target: string,
  animation: AnimationMetadata,
  // without { optional: true } the query will throw an error if the element is not found
  options: AnimationQueryOptions = { optional: true },
): AnimationQueryMetadata => query(target, animation, options);

/**
 * AI Assistant animation trigger selectors and styles mapped by state
 */

const positioningContainer = {
  name: 'aiAssistant',
  styles: {
    chatMobile: {
      bottom: 0,
      right: `-${gradientBorderWidth}`,
      left: `-${gradientBorderWidth}`,
      height: '{{chatHeight}}',
      maxHeight: '100vh',
      opacity: 1,
      paddingTop: 'var(--ion-safe-area-top)',
      width: 'initial',
    },
    default: {
      bottom: `calc(var(--spacing-3xl) - ${pillPadding})`,
      left: `calc(var(--spacing-3xl) - ${pillPadding})`,
      height: 'auto',
      opacity: 1,
    },
  },
} as const;

const layoutContainer = {
  name: 'layoutContainer',
  get selector() {
    return `@${this.name}`;
  },
  styles: {
    void: {
      opacity: 0,
      maxHeight: buttonContainerDimensions,
      width: buttonContainerDimensions,
      borderRadius: containerBorderRadius,
      margin: 0,
    },
    button: {
      width: buttonContainerDimensions,
      maxHeight: buttonContainerDimensions,
      borderRadius: containerBorderRadius,
      margin: pillPadding,
    },
    pill: {
      width: '100%',
      maxWidth: `min(580px, calc(100vw - 2 * ${positioningContainer.styles.default.left}))`,
      maxHeight: '75px',
      borderRadius: pillBorderRadius,
      margin: 0,
    },
    chat: { width: '375px', maxWidth: '100%', height: '700px', maxHeight: '90vh', borderRadius: containerBorderRadius, margin: 0 },
    chatMobile: {
      width: `calc(100vw + 2 * ${gradientBorderWidth})`,
      maxWidth: '100%',
      height: '100%',
      maxHeight: '95%',
      borderRadius: `${containerBorderRadius} ${containerBorderRadius} 0 0`,
      paddingBottom: 0,
      margin: 0,
    },
  },
} as const;

const conversationContainer = {
  selector: 'app-ai-assistant',
  styles: {
    button: { padding: 0, height: 'auto' },
    pill: { padding: pillPadding, height: 'auto' },
    chat: { padding: 'var(--spacing-lg)', height: '100%' },
    chatMobile: {
      padding: 'var(--spacing-lg) var(--spacing-lg) calc(var(--spacing-lg) + var(--ion-safe-area-bottom))',
      height: '100%',
    },
  },
} as const;

const avatar = {
  name: 'avatar',
  get selector() {
    return `@${this.name}`;
  },
  styles: {
    chat: { width: chatPillDimensions, height: chatPillDimensions },
    buttonAndPill: { width: buttonDimensions, height: buttonDimensions },
  },
} as const;

//######################################## Transitions ########################################

/**
 * Button and pill transitions
 */
const buttonToPillTransition: AnimationMetadata[] = [
  group([
    createQuery(layoutContainer.selector, animate(defaultTransition, style(layoutContainer.styles.pill))),
    createQuery('@content', animateChild()),
    createQuery('@topBarContent', animateChild()),
    createQuery(avatar.selector, animateChild()),
  ]),
];

const pillToButtonTransition: AnimationMetadata[] = [
  group([
    createQuery(layoutContainer.selector, animate(defaultTransition, style(layoutContainer.styles.button))),
    createQuery('@content', animateChild()),
    createQuery('@topBarContent', animateChild()),
    createQuery(avatar.selector, animateChild()),
  ]),
];

/**
 * Transitions to chat state
 */
const buttonToChatTransition: AnimationMetadata[] = [
  sequence([
    // set current state styles for the animation
    createQuery(conversationContainer.selector, style(conversationContainer.styles.button)),
    createQuery('@topBarContent', style({ opacity: 0, width: 'auto' })),
    createQuery(avatar.selector, style({ ...avatar.styles.chat })),
    group([
      animate(defaultTransition),
      createQuery(
        layoutContainer.selector,
        animate(
          defaultTransition,
          keyframes([style({ ...layoutContainer.styles.button, offset: 0 }), style({ ...layoutContainer.styles.chat, offset: 1 })]),
        ),
      ),
      createQuery(conversationContainer.selector, animate(defaultTransition, style(conversationContainer.styles.chat))),
      createQuery('@content', animate(defaultTransition, style({ padding: 0 }))),
      createQuery(avatar.selector, animateChild()),
    ]),
    createQuery('@topBarContent', animate(defaultTransition, style({ opacity: 1 }))),
  ]),
];

const pillToChatTransition: AnimationMetadata[] = [
  sequence([
    // set current state styles for the animation
    createQuery(conversationContainer.selector, style(conversationContainer.styles.pill)),
    createQuery('@topBarContent', style({ width: 'auto', opacity: 0 })),
    group([
      animate(defaultTransition),
      createQuery(
        layoutContainer.selector,
        animate(
          defaultTransition,
          keyframes([
            style({
              ...layoutContainer.styles.pill,
              maxWidth: layoutContainer.styles.chat.maxWidth,
              width: layoutContainer.styles.chat.width,
              offset: 0,
            }),
            style({ ...layoutContainer.styles.chat, offset: 1 }),
          ]),
        ),
      ),
      createQuery(conversationContainer.selector, animate(defaultTransition, style(conversationContainer.styles.chat))),
      createQuery(avatar.selector, animateChild()),
    ]),
    createQuery('@topBarContent', animate(defaultTransition, style({ opacity: 1 }))),
  ]),
];

/**
 * Transition to mobile chat state
 */
const buttonToChatMobileTransition: AnimationMetadata[] = [
  sequence([
    // set current state styles for the animation
    createQuery(conversationContainer.selector, style(conversationContainer.styles.button)),
    createQuery('@topBarContent', style({ opacity: 0, width: 'auto' })),
    createQuery(avatar.selector, style({ width: buttonDimensions, height: buttonDimensions })),
    group([
      // animate the positioning container
      animate(defaultTransition),
      createQuery(
        layoutContainer.selector,
        animate(
          defaultTransition,
          keyframes([
            style({
              ...layoutContainer.styles.button,
              offset: 0,
            }),
            style({ ...layoutContainer.styles.chatMobile, offset: 1 }),
          ]),
        ),
      ),
      createQuery(conversationContainer.selector, animate(defaultTransition, style(conversationContainer.styles.chatMobile))),
      createQuery('@content', animate(defaultTransition, style({ padding: 0 }))),
      createQuery(avatar.selector, animateChild()),
    ]),
    createQuery('@topBarContent', animate(defaultTransition, style({ opacity: 1 }))),
  ]),
];

const pillToChatMobileTransition: AnimationMetadata[] = [
  sequence([
    // set current state styles for the animation
    createQuery(conversationContainer.selector, style(conversationContainer.styles.pill)),
    createQuery('@topBarContent', style({ width: 'auto', opacity: 0 })),
    group([
      animate(defaultTransition),
      createQuery(
        layoutContainer.selector,
        animate(
          defaultTransition,
          keyframes([style({ ...layoutContainer.styles.pill, offset: 0 }), style({ ...layoutContainer.styles.chatMobile, offset: 1 })]),
        ),
      ),
      createQuery(conversationContainer.selector, animate(defaultTransition, style(conversationContainer.styles.chatMobile))),
      createQuery(avatar.selector, animateChild()),
    ]),
    createQuery('@topBarContent', animate(defaultTransition, style({ opacity: 1 }))),
  ]),
];

//######################################## Animation triggers ########################################

/**
 * This is the most outer animation trigger that controls the whole animation flow
 */
const assistantPositioningContainerAnimation = trigger(positioningContainer.name, [
  state('button, pill, chat', style(positioningContainer.styles.default)),
  state('chat-mobile', style(positioningContainer.styles.chatMobile), { params: { chatHeight: '100vh' } }),
  state('void', style({ ...positioningContainer.styles.default, opacity: 0 })),
  transition('void => *', animate(defaultTransition)),
  transition('button => pill', buttonToPillTransition),
  transition('pill => button', pillToButtonTransition),
  transition('button => chat', buttonToChatTransition),
  transition('pill => chat', pillToChatTransition),
  transition('button => chat-mobile', buttonToChatMobileTransition),
  transition('pill => chat-mobile', pillToChatMobileTransition),
  transition(
    'chat => button, chat-mobile => button',
    group([
      style(positioningContainer.styles.default),
      createQuery(layoutContainer.selector, style(layoutContainer.styles.button)),
      createQuery('@content', style({ padding: 0 })),
      createQuery('@topBarContent', style({ opacity: 0 })),
      createQuery(avatar.selector, style(avatar.styles.buttonAndPill)),
    ]),
  ),
]);

/**
 * Animates the width, height and border radius of the assistant
 */
const assistantLayoutContainerAnimation: AnimationTriggerMetadata = trigger(layoutContainer.name, [
  state('void', style(layoutContainer.styles.void)),
  state('button', style(layoutContainer.styles.button)),
  state('pill', style(layoutContainer.styles.pill)),
  state('chat', style(layoutContainer.styles.chat)),
  state('chat-mobile', style(layoutContainer.styles.chatMobile)),
]);

/**
 * Animates spacing during transitions
 */
const assistantContentAnimation: AnimationTriggerMetadata = trigger('content', [
  state('button', style({ padding: 0, borderRadius: 'inherit' })),
  state('pill', style({ padding: conversationContainer.styles.pill.padding, borderRadius: 'inherit' })),
  state(
    'chat, chat-mobile',
    style({
      padding: 0,
      borderRadius: `calc(${containerBorderRadius} - ${gradientBorderWidth}) calc(${containerBorderRadius} - ${gradientBorderWidth}) 0 0`,
    }),
  ),
  state(
    'chat, chat-mobile',
    style({ padding: 0, borderRadius: `calc(${layoutContainer.styles.chat.borderRadius} - ${gradientBorderWidth})` }),
  ),
  transition('button <=> pill, button => chat, button => chat-mobile', animate(defaultTransition)),
]);

/**
 * Animates the notification message and the top bar of the assistant
 */
const assistantTopBarContentAnimation: AnimationTriggerMetadata = trigger('topBarContent', [
  state('button, void', style({ opacity: 0 })),
  state('pill', style({ opacity: 1 })),
  state('chat, chat-mobile', style({ opacity: 1 })),
  transition('button <=> pill', animate('.8s ease')),
  transition('button => chat, button => chat-mobile, void => *', animate('.6s ease')),
  transition('* => void', animate(defaultTransition)),
]);

/**
 * Animates the chat input field and the chat messages
 */
const assistantFadeUpAnimation: AnimationTriggerMetadata = trigger('fadeUp', [
  state('void', style({ opacity: 0, transform: 'translateY(2rem)' })),
  state('*', style({ opacity: 1, transform: 'translateY(0)' })),
  transition('void => *', animate(defaultTransition)),
]);

/**
 * Animates individual chat messages
 */
const assistantFadeInOutAnimation: AnimationTriggerMetadata = trigger('fadeInOut', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition('void <=> *', animate(defaultTransition)),
]);

/**
 * Animates the scroll to bottom of the chat button
 */
const assistantFadeInOutScaleAnimation: AnimationTriggerMetadata = trigger('fadeInOutScale', [
  state('void', style({ opacity: 0, transform: 'scale(0)' })),
  state('*', style({ opacity: 1, transform: 'scale(1)' })),
  transition('void <=> *', animate(defaultTransition)),
]);

/**
 * Animates the size of the avatar in the top bar
 */
const assistantAvatarAnimation: AnimationTriggerMetadata = trigger(avatar.name, [
  state('chat, chat-mobile', style(avatar.styles.chat)),
  state('*', style(avatar.styles.buttonAndPill)),
  transition('pill => chat, button => chat, pill => chat-mobile, button => chat-mobile, button <=> pill', animate(defaultTransition)),
]);

export const aiAssistantAnimations = {
  assistantPositioningContainerAnimation,
  assistantLayoutContainerAnimation,
  assistantContentAnimation,
  assistantTopBarContentAnimation,
  assistantFadeInOutScaleAnimation,
  assistantFadeUpAnimation,
  assistantFadeInOutAnimation,
  assistantAvatarAnimation,
};
