<div *ngIf="!isMinimized()" class="devtools-container">
  <div class="header">
    <div class="header-container">
      <h3 class="display-xxs-bold">StudySmarter Dev Tools</h3>
      <button uiIconButton secondary size="sm" class="close-devtools-button" aria-label="Close devtools" (click)="toggleIsMinimized()">
        <i class="app-icon-close"></i>
      </button>
    </div>

    <p>Environment: {{ environment }}</p>
  </div>

  <div class="content">
    <div class="grid">
      <div>
        <div class="content-item">
          <label for="environment" class="info-item-label">Environment</label>
          <select id="environment" [ngModel]="environment" (ngModelChange)="changeEnvironment($event)">
            <option [value]="devToolsEnvironment.DEV">DEV</option>
            <option [value]="devToolsEnvironment.PROD">PROD</option>
          </select>
        </div>

        <div class="content-item">
          <label for="aia-gpt-version" class="info-item-label">AI Assistant GPT version</label>
          <select id="aia-gpt-version" [ngModel]="aiAssistantGptVersion()" (ngModelChange)="changeAiAssistantGptVersion($event)">
            <option [value]="3">GPT-4o mini</option>
            <option [value]="4">GPT-4o</option>
          </select>
        </div>

        <label class="info-item-label">🔥 Streak customization (requires reload)</label>
        <div class="content-item">
          <div class="checkbox-container">
            <input type="checkbox" id="streak-checkbox" [(ngModel)]="streakCustomizationEnabled" />
            <label for="streak-checkbox">Customize Streak Response</label>
          </div>

          <label for="current-streak">Current Streak </label>
          <input id="current-streak" type="number" min="0" style="width: 60px" [(ngModel)]="currentStreak" />
          <br />

          @for (weekday of weekdays; track weekday) {
            <label class="info-item-label" [for]="weekday">{{ weekday }}</label>
            <select [id]="weekday" [ngModel]="weeklyStreak()[weekday]" (ngModelChange)="updateStreakDayStatus(weekday, $event)">
              <option value="idle">idle</option>
              <option value="open">open</option>
              <option value="missed">missed</option>
              <option value="achieved">achieved</option>
            </select>
            <br />
          }
        </div>

        <div class="content-item">
          <h4 class="category-header">Theming</h4>

          <div class="buttons-container">
            @for (option of themeOptions; track option.translationKey) {
              <button uiButton (click)="changeTheme(option.value)">
                {{ option.translationKey | translate }}
              </button>
            }
          </div>
        </div>

        @for (info of additionalDevtoolsInformation(); track info.header) {
          <div class="content-item">
            <h4 class="category-header">{{ info.header }}</h4>
            <ng-container *ngrxLet="info.data$; let data">
              @for (dataItem of data; track dataItem.label) {
                <div class="additional">
                  <span class="info-item-label">{{ dataItem.label }}:</span>
                  <span class="info-item-value">{{ dataItem.content }}</span>
                </div>
              }
            </ng-container>
          </div>
        }
      </div>

      <div class="content-item">
        <h4 class="category-header">Feature Toggles</h4>
        @for (feature of featureToggles() | keyvalue; track feature.key) {
          @if (showFeatureToggle($any(feature.key))) {
            <div class="checkbox-container">
              <input
                type="checkbox"
                [id]="feature.key"
                [ngModel]="feature.value"
                (ngModelChange)="setFeatureToggle($any(feature.key), $event)"
              />
              <label [htmlFor]="feature.key">{{ feature.key }}</label>
            </div>
          }
        }
      </div>

      <div class="content-item">
        <h4 class="category-header">Actions</h4>
        <div class="actions">
          @for (action of additionalDevtoolsActions(); track action.label) {
            <button uiButton secondary size="sm" (click)="action.action()">
              {{ action.label }}
            </button>
          }

          <div>
            <label class="info-item-label"><input type="checkbox" [formControl]="safeAreasFormControl" /> Simulate safe areas </label>
          </div>
        </div>
      </div>
    </div>

    <div class="content-item experiments">
      <h4 class="category-header">Amplitude Experiments (requires reload after changing)</h4>
      <ng-container *ngIf="experiments$ | async as experiments">
        @for (experiment of experiments | keyvalue; track experiment.key) {
          @if (experimentToOptionsMapping[$any(experiment.key)]; as experimentOptions) {
            <div class="experiment">
              <label class="info-item-label" [for]="experiment.key">{{ experiment.key }}:</label>
              <select [id]="experiment.key" [ngModel]="experiment.value" (ngModelChange)="changeTestGroup($any(experiment.key), $event)">
                @for (option of experimentOptions; track option.label) {
                  <option [ngValue]="option.value">
                    {{ option.label }}
                  </option>
                }
              </select>
            </div>
          }
        }
      </ng-container>
    </div>

    <div class="content-item translation-toolbar">
      <h4 class="category-header">Translations:</h4>
      <app-translation-toolbar />
    </div>
  </div>
</div>

<button
  class="open-devtools"
  uiIconButton
  secondary
  cdkDrag
  size="lg"
  aria-label="Open devtools"
  [class.hidden]="!isMinimized()"
  [cdkDragFreeDragPosition]="currentDragPosition()"
  (cdkDragReleased)="snapToSide()"
  (click)="toggleIsMinimized()"
>
  <i class="app-icon-video-game"></i>
</button>
