import { Overlay } from '@angular/cdk/overlay';
import { ElementRef, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { Observable, tap } from 'rxjs';

import { switchToVoid } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { AssistantDialogComponent } from './assistant-dialog.component';

@Injectable({ providedIn: 'root' })
export class AssistantDialogService {
  private _dialogRef: DialogRef | undefined;

  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _overlay: Overlay,
  ) {}

  isOpen(): boolean {
    return !isNil(this._dialogRef);
  }

  openDialog(flexibleConnectedTo: ElementRef<HTMLElement>): Observable<void> {
    this._dialogRef = this._platformModalService.createContextMenu({
      component: AssistantDialogComponent,
      disposeOnNavigation: false,
      webOptions: {
        panelClass: 'assistant-dialog',
        maxWidth: '400px',
        maxHeight: '100vh',
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(flexibleConnectedTo)
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'bottom',
            },
          ]),
      },
    });

    return this._dialogRef.afterClosed().pipe(
      tap(() => {
        this._dialogRef = undefined;
      }),
      switchToVoid(),
    );
  }
}
