import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SlidesetCardComponent } from '@stsm/documents/feature/components/slideset-card/slideset-card.component';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';

import type { AiAssistantMessage } from '../../models/conversation/message';
import { AiAssistantMessageFactory, LOADING_MESSAGE_ID } from '../../models/conversation/message';
import { aiAssistantAnimations } from '../ai-assistant/ai-assistant-animations';
import { ChatBubbleComponent } from '../chat-bubble/chat-bubble.component';

@Component({
  selector: 'app-chat-conversation',
  standalone: true,
  imports: [ChatBubbleComponent, ButtonComponent, TranslatePipe, SlidesetCardComponent],
  templateUrl: './chat-conversation.component.html',
  styleUrl: './chat-conversation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [aiAssistantAnimations.assistantFadeInOutAnimation],
})
export class ChatConversationComponent {
  @Input() messages: AiAssistantMessage[] = [];
  @Input() isGeneratingResponse: boolean = false;

  readonly generatingResponseMessage: AiAssistantMessage = AiAssistantMessageFactory.create({
    id: LOADING_MESSAGE_ID,
    role: 'assistant',
  });
}
