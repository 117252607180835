import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';
import { ConfettiCanvasComponent } from '@stsm/ui-components/confetti-canvas';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

@Component({
  selector: 'app-first-steps-completed-dialog',
  templateUrl: './first-steps-completed-dialog.component.html',
  styleUrls: ['./first-steps-completed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, ConfettiCanvasComponent, TranslatePipe],
  standalone: true,
})
export class FirstStepsCompletedDialogComponent implements AfterViewInit {
  @ViewChild(ConfettiCanvasComponent)
  private readonly _confettiCanvas!: ConfettiCanvasComponent;

  constructor(private readonly _dialogRef: DialogRef<FirstStepsCompletedDialogComponent>) {}

  ngAfterViewInit(): void {
    void this._confettiCanvas.triggerConfetti();
  }

  protected close(): void {
    this._dialogRef.dismiss();
  }
}
