import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, filter, switchMap, timer } from 'rxjs';

import { DurationInMilliseconds } from '@stsm/date/enums/duration-in-milliseconds';
import { DevtoolsActionLabel, DevtoolsService } from '@stsm/devtools/services/devtools.service';
import { isFullMatch } from '@stsm/shared/util/regex.util';
import { webReleaseTagRegex } from '@stsm/shared/util/regex-patterns';

import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app-settings';

import { UpdateService } from './update.service';
import { UpdateDialogService } from './update-dialog/update-dialog.service';

const INTERVAL = DurationInMilliseconds.HOUR * 6;

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-update',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateComponent implements OnDestroy {
  version: string = AppSettings.VERSION_NUMBER;

  constructor(
    private readonly _updateService: UpdateService,
    private readonly _updateDialogService: UpdateDialogService,
    private readonly _devtoolsService: DevtoolsService,
  ) {
    // exclude release env
    if (environment.production && isFullMatch({ value: AppSettings.VERSION_NUMBER, regex: webReleaseTagRegex() })) {
      timer(30_000, INTERVAL)
        .pipe(
          switchMap(() => this._updateService.getLatestVersion()),
          switchMap((response: Response) => (response.ok ? response.json() : EMPTY)),
          filter((result: { version: string }) => result.version !== this.version),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this._updateDialogService.open();
        });
    }

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.REFRESH_DIALOG,
      action: () => {
        this._devtoolsService.closeDevtools();
        this._updateDialogService.open();
      },
    });
  }

  ngOnDestroy(): void {
    this._devtoolsService.unregisterAdditionalAction(DevtoolsActionLabel.REFRESH_DIALOG);
  }
}
