import { DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Event as RouterEvent, Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addIcons } from 'ionicons';
import { checkmarkOutline, radioButtonOnOutline } from 'ionicons/icons';
import { isNil } from 'lodash-es';
import { distinctUntilChanged, filter, firstValueFrom, map, switchMap, take, tap } from 'rxjs';

import { AdRewardCheckService } from '@stsm/advertisement/components/ad-rewards/ad-reward-check.service';
import { AdBlockerDetectorService } from '@stsm/advertisement/services/ad-blocker-detector.service';
import { PixelService } from '@stsm/advertisement/services/pixel.service';
import { UserSegmentationService } from '@stsm/advertisement/user-segmentation/user-segmentation.service';
import { AmplitudeService, AmplitudeUserProperty } from '@stsm/analytics';
import { DevtoolsComponent } from '@stsm/devtools/components/devtools/devtools.component';
import { PromptDialogService } from '@stsm/devtools/components/prompt-dialog/prompt-dialog.service';
import { DevtoolsActionLabel, DevtoolsService } from '@stsm/devtools/services/devtools.service';
import { FlashcardImportDialogService } from '@stsm/flashcards/feature/flashcard-import/flashcard-import-dialog.service';
import { LearnModeTaskService } from '@stsm/flashcards/feature/services/learn-mode-task.service';
import { WhatsNewId, WhatsNewModalService } from '@stsm/global/composite/components/whats-new-modal/whats-new-modal.service';
import { PageViewTrackingService } from '@stsm/global/composite/services/page-view-tracking.service';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { TitleService } from '@stsm/global/composite/services/title.service';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { LibraryStore } from '@stsm/library/data-access';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { NetworkService } from '@stsm/shared/services/network.service';
import { arraysContainSameValues } from '@stsm/shared/util/array-util';
import { StreakDialogService } from '@stsm/streak/feature/services/streak-dialog.service';
import { CreateEditStudyplanEventModalService } from '@stsm/studyplan/feature/services/create-edit-studyplan-event-modal.service';
import { StudysetLanguagesService } from '@stsm/studysets/services/studyset-languages.service';
import { SystemMaintenanceService } from '@stsm/system-maintenance/services/system-maintenance.service';
import { SocketService } from '@stsm/user/feature/services/socket.service';
import { TroubleshootingModeService } from '@stsm/user/feature/services/troubleshooting-mode.service';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { environment } from '../environments/environment';

import { UpdateComponent } from './common/app-update/update.component';
import { CookieUtilComponent } from './components/cookie-util/cookie-util.component';
import { ContextualTutorialService } from './contextual-tutorials/services/contextual-tutorial.service';
import { SurveyService } from './feed/services/survey.service';
import { OnboardingStore } from './onboarding/onboarding-store.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { FeatureTimeTrackingService } from './shared/services/feature-time-tracking.service';
import { LoggingService } from './shared/services/logging.service';
import { PremiumService } from './shared/services/premium.service';
import { PremiumModalWebService } from './shared/services/premium-modal-web.service';
import { TargetMarketService } from './shared/services/target-market.service';
import { TimeTrackingService } from './shared/services/time-tracking.service';
import { SystemMaintenanceBannerComponent } from './system-maintenance/components/system-maintenance-banner/system-maintenance-banner.component';
import { isWebsiteEntryUrl } from './website-entry-url';

const EXTERNAL_STYLES: string[] = ['font-awesome-min.css', 'froala-editor-pkgd-min.css', 'froala-image-min.css'];

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, UpdateComponent, DevtoolsComponent, NgIf, CookieUtilComponent, SystemMaintenanceBannerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('pixelContainer', { read: ViewContainerRef, static: true }) private readonly _pixelContainerRef?: ViewContainerRef;

  get devtoolsEnabled(): boolean {
    return this._devtoolsService.areDevtoolsAllowed;
  }

  @HostBinding('class.redesign')
  get isRedesign(): boolean {
    return true;
  }

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _loggingService: LoggingService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _titleService: TitleService,
    private readonly _router: Router,
    private readonly _featureTimeTrackingService: FeatureTimeTrackingService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _translationService: TranslationService,
    private readonly _whatsNewModalService: WhatsNewModalService,
    private readonly _premiumService: PremiumService,
    private readonly _premiumModalService: PremiumModalWebService,
    private readonly _themingStore: ThemingStore,
    private readonly _studysetLanguageService: StudysetLanguagesService,
    private readonly _devtoolsService: DevtoolsService,
    private readonly _systemMaintenanceService: SystemMaintenanceService,
    private readonly _pixelService: PixelService,
    private readonly _loggerService: LoggerService,
    private readonly _userSegmentationService: UserSegmentationService,
    private readonly _onboardingStore: OnboardingStore,
    private readonly _contextualTutorialService: ContextualTutorialService,
    private readonly _pageViewTrackingService: PageViewTrackingService,
    private readonly _adBlockerDetectorService: AdBlockerDetectorService,
    private readonly _amplitudeService: AmplitudeService,
    private readonly _targetMarketService: TargetMarketService,
    private readonly _timeTrackingService: TimeTrackingService,
    private readonly _socketService: SocketService,
    private readonly _networkService: NetworkService,
    private readonly _adRewardCheckService: AdRewardCheckService,
    private readonly _surveyService: SurveyService,
    private readonly _createEditStudyplanEventModalService: CreateEditStudyplanEventModalService,
    private readonly _libraryStore: LibraryStore,
    private readonly _flashcardImportDialogService: FlashcardImportDialogService,
    private readonly _troubleshootingModeService: TroubleshootingModeService,
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _promptDialogService: PromptDialogService,
    private readonly _routerStoreFacade: RouterStoreFacade,
    private readonly _streakDialogService: StreakDialogService,
    private readonly _learnModeTaskService: LearnModeTaskService,
  ) {
    addIcons({ checkmarkOutline, radioButtonOnOutline });

    this._targetMarketService.init();
    this._studysetLanguageService.prefetchAllStudysetLanguages();
    this._themingStore.init();
    this._systemMaintenanceService.init();
    this._onboardingStore.init();
    this._timeTrackingService.init();
    this._featureTimeTrackingService.init();
    this._surveyService.init();
    this._createEditStudyplanEventModalService.init();
    this._flashcardImportDialogService.init();
    this._streakDialogService.init();
    this._learnModeTaskService.init();

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.LOG_CURRENT_ROUTE,
      action: () => {
        console.warn('current route', this._router.url);
      },
    });

    this._routerStoreFacade.url$
      .pipe(
        filter((url: string) => !isWebsiteEntryUrl(url)),
        take(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        // Always calling initAmplitude on the first non-website-entry url acts as failover in case of an error which
        // might have prevented the initialization of Amplitude.
        this._amplitudeService.initAmplitude().catch((error: unknown) => {
          this._loggerService.warn('Error during amplitude initialization', error);
        });
      });

    this._pageViewTrackingService.enableTracking();
    this._contextualTutorialService.listen();
    this._socketService.init();
    this._networkService.init();
    this._libraryStore.init();
    this._troubleshootingModeService.checkForTroubleshootingMode();
  }

  ngOnInit(): void {
    // track browser language
    this._analyticsService.trackSingularEvent('detected_browser_language', { language: navigator.language });
    this._analyticsService.trackSingularEvent('app_start');
    this._analyticsService.trackEvent({
      category: 'app',
      action: 'screen_dimensions',
      properties: {
        width: screen.width,
        height: screen.height,
      },
    });

    this._titleService.setTitle();

    void this._loadAllExternalStyles();

    if (environment.SHOW_APPLICATION_CTA) {
      this._loggingService.printApplicationCta();
    }

    this._router.events
      .pipe(
        filter((e: RouterEvent) => e instanceof RoutesRecognized),
        take(1),
        tap((event: RoutesRecognized) => {
          const source = this._router.parseUrl(event.url).queryParams['source'];
          this._analyticsService.trackEvent({
            category: 'app',
            action: 'app_start',
            properties: source ? { source } : undefined,
          });

          this._userSegmentationService.onAppStart();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this._userStoreFacade.user$
      .pipe(
        map((user: User) => user.maintopicIds),
        distinctUntilChanged(arraysContainSameValues),
        tap((maintopicIds: number[]) => this._analyticsService.setAmplitudeUserProperty(AmplitudeUserProperty.MAINTOPIC_IDS, maintopicIds)),
        untilDestroyed(this),
      )
      .subscribe();

    this._userStoreFacade.userAvailable$
      .pipe(
        tap((user: User) => {
          this._loggerService.debug('START SESSION');
          this._analyticsService.trackEvent({
            category: 'app',
            action: 'start_session',
          });
          this._analyticsService.trackSingularEvent('start_session');

          if (user.language !== this._translationService.currentLanguage()) {
            this._translationService.useLanguage(user.language);
          }
        }),
        switchMap((user: User) => {
          return this._translationService.translationsReady$.pipe(
            filter(Boolean),
            switchMap(() => this._onboardingStore.isRunning$),
            filter((isOnboardingRunning: boolean) => !isOnboardingRunning),
            take(1),
            switchMap(() => this._whatsNewModalService.getVisibleWhatsNewIds([WhatsNewId.AI_MULTIPLE_CHOICE], 'webapp', user)),
            switchMap((ids: WhatsNewId[]) => this._whatsNewModalService.showModal(ids, user)),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.TRIGGER_WHATS_NEW,
      action: async () => {
        this._browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.SEEN_WHATS_NEW_IDS);
        const user = await firstValueFrom(this._userStoreFacade.userAvailable$, { defaultValue: null });

        if (user) {
          const ids = await firstValueFrom(
            this._whatsNewModalService.getVisibleWhatsNewIds([WhatsNewId.AI_MULTIPLE_CHOICE], 'webapp', user),
          );
          this._whatsNewModalService.showModal(ids, user);
        }
      },
    });

    this._premiumService.init();
    this._premiumModalService.init();

    if (!isNil(this._pixelContainerRef)) {
      this._pixelService.registerViewContainerRef(this._pixelContainerRef);
    }

    this._adBlockerDetectorService.init();
    this._adRewardCheckService.init();

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.EDIT_PROMPT_TEMPLATE,
      action: () => {
        this._promptDialogService.openDialog();
      },
    });
  }

  private async _loadAllExternalStyles(): Promise<void> {
    for (const externalStyle of EXTERNAL_STYLES) {
      await this._loadExternalStyles(externalStyle);
    }
  }

  private _loadExternalStyles(styleUrl: string): Promise<Event> {
    return new Promise((resolve: (value: Event) => void) => {
      const styleElement = this._document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this._document.body.appendChild(styleElement);
    });
  }
}
