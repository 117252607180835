import { differenceInSeconds } from 'date-fns';
import { floor } from 'lodash-es';
import { interval, map, Observable, startWith, takeWhile } from 'rxjs';

import { createSafeDate } from '../functions/safe-date';

/**
 * Starts a countdown from the time it is called and stops after 24 hours.
 * @returns {Observable<string>} observable emitting the countdown time as a string in HH:mm:ss format.
 */

export function getCountdownString(endTime: Date): Observable<string> {
  return interval(1000).pipe(
    startWith(0),
    map(() => formatTimeLeft(endTime)),
    takeWhile((timeLeft: string) => timeLeft !== '00:00:00'),
  );
}

/**
 * Formats the time left until the countdown ends.
 * @param {Date} endTime - The end time as a Date object.
 * @returns {string} Formatted time as a string in HH:mm:ss format.
 */
export function formatTimeLeft(endTime: Date): string {
  const now: Date = createSafeDate(new Date());
  const secondsLeft: number = differenceInSeconds(createSafeDate(endTime), now);

  if (secondsLeft <= 0) {
    return '00:00:00';
  }

  const hours: number = floor(secondsLeft / 3600);
  const minutes: number = floor((secondsLeft % 3600) / 60);
  const seconds: number = secondsLeft % 60;

  return [hours, minutes, seconds].map((unit: number) => String(unit).padStart(2, '0')).join(':');
}
