import { AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavController } from '@ionic/angular/standalone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { combineLatest, distinctUntilKeyChanged, firstValueFrom, map, Observable, startWith } from 'rxjs';

import { AbTestService, ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { AssistantButtonComponent } from '@stsm/assistant/components/assistant-button/assistant-button.component';
import { AssistantStore } from '@stsm/assistant/services/assistant-store.service';
import { SharingBaseService } from '@stsm/global/composite/services/sharing-base.service';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { UsersnapService } from '@stsm/global/composite/services/usersnap.service';
import { SHARING_SERVICE } from '@stsm/global/composite/tokens/sharing-service.token';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { Tab } from '@stsm/shared/enums/tab';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { ButtonComponent } from '@stsm/ui-components/button';
import { ToolbarItemComponent } from '@stsm/ui-components/toolbar-item/toolbar-item.component';
import { User } from '@stsm/user/models/user';
import { canShowJobsForUserCountry } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { NavigationRoute } from '../../models/navigation-route';
import { ScaffoldStore } from '../../scaffold-store.service';
import { PLATFORM_NAVIGATION_ROUTES } from '../../tokens/navigation-routes.token';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[appNavigation]',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ToolbarItemComponent,
    NgClass,
    TranslatePipe,
    NgForOf,
    NgIf,
    ButtonComponent,
    LetDirective,
    NgTemplateOutlet,
    AssistantButtonComponent,
  ],
})
export class NavigationComponent {
  logo$: Observable<LogoSource>;

  protected readonly LogoSource: typeof LogoSource = LogoSource;

  protected readonly currentTab$: Observable<Tab | undefined> = this._scaffoldStore.currentTab$;

  protected readonly isAssistantVisible: Signal<boolean> = this._assistantStore.isVisible;

  protected readonly isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  protected readonly isFullPageRouteActive: Signal<boolean | undefined> = this._layoutStore.isFullPageRouteActive;

  protected readonly isAiAssistantEnabled$: Observable<boolean> = this._abTestService.isAiAssistantEnabled();

  protected readonly assistantMobilePosition: number = 1;

  protected readonly showJobsTab: Signal<boolean>;

  protected readonly Tab: typeof Tab = Tab;

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(PLATFORM_NAVIGATION_ROUTES) protected readonly navigationRoutes: NavigationRoute[],
    private readonly _scaffoldStore: ScaffoldStore,
    private readonly _navController: NavController,
    @Inject(SHARING_SERVICE) private readonly _sharingService: SharingBaseService,
    private readonly _usersnapService: UsersnapService,
    private readonly _layoutStore: LayoutStore,
    private readonly _assistantStore: AssistantStore,
    private readonly _themingStore: ThemingStore,
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    private readonly _abTestService: AbTestService,
    private readonly _userStoreFacade: UserStoreFacade,
  ) {
    this.logo$ = this._themingStore.darkThemeActive$.pipe(
      map((isDarkThemeActive: boolean) => this._getLogo(isDarkThemeActive)),
      startWith(LogoSource.HAT_BLACK),
    );

    this.showJobsTab = toSignal(
      combineLatest([
        this._userStoreFacade.user$.pipe(
          distinctUntilKeyChanged('countryId'),
          map((user: User) => canShowJobsForUserCountry(user.countryId)),
        ),
        this._layoutStore.isMobileLayout$,
      ]).pipe(map(([canShowJobsTab, isMobileLayout]: [boolean, boolean]) => canShowJobsTab && !isMobileLayout)),
      { initialValue: false },
    );
  }

  protected async onNavItemClicked(tab: Tab): Promise<void> {
    const currentTab = await firstValueFrom(this.currentTab$);
    const shouldAnimate = tab === currentTab;

    void this._navController.navigateRoot(tab, { animated: shouldAnimate, animationDirection: 'back' });
  }

  protected onLogoClick(): void {
    void this.onNavItemClicked(Tab.HOME);
  }

  protected onInviteFriendsClick(): void {
    this._analyticsService.trackEvent({ category: 'app', action: 'open_share_app' });
    this._sharingService.shareApp();
  }

  protected openFeedbackModal(): void {
    this._usersnapService.openFeedbackModal();
  }

  private _getLogo(isDarkThemeActive: boolean): LogoSource {
    if (this._environment.CONTENT_CREATORS) {
      return LogoSource.HAT_CONTENT;
    }

    return isDarkThemeActive ? LogoSource.HAT_WHITE : LogoSource.HAT_BLACK;
  }
}
