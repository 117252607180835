import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-response-loading-animation',
  standalone: true,
  templateUrl: './response-loading-animation.component.html',
  styleUrls: ['./response-loading-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'aria-busy': 'true',
    'aria-live': 'polite',
  },
})
export class ResponseLoadingAnimationComponent {}
