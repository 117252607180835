import { inject, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';
import { isError } from 'lodash-es';

import { SentryService } from '@stsm/shared/services/sentry.service';

const chunkLoadErrors: string[] = ['Failed to fetch dynamically imported module', 'Importing a module script failed.'];

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler extends SentryErrorHandler {
  private readonly _sentryService: SentryService = inject(SentryService);

  // The constructor is necessary
  constructor() {
    super();
  }

  override handleError(error: unknown): void {
    if (isError(error) && chunkLoadErrors.some((chunkLoadError: string) => error.message.includes(chunkLoadError))) {
      this._sentryService.reportToSentry(
        // eslint-disable-next-line no-restricted-syntax
        `CHUNK LOAD ISSUE: navigationStart: ${new Date(performance.timing.navigationStart).toString()}`,
        error,
      );
    }

    // Call Sentry's error handler to capture errors
    super.handleError(error);
  }
}
