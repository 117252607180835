import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';

import { Ad } from '@stsm/global/models/ad';
import { LeadAnswer, LeadQuestion, LeadSubmitData } from '@stsm/global/models/lead-question';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { Id } from '@stsm/shared/types/id';
import { ToFormControls } from '@stsm/shared/types/to-form-controls';
import { trackByDefault } from '@stsm/shared/util/generic-utils';
import { phoneNumberRegex } from '@stsm/shared/util/regex-patterns';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { IllustrationDirective } from '@stsm/ui-components/disco-color/illustration.directive';
import { FormErrorComponent, FormFieldComponent } from '@stsm/ui-components/form-field';
import { LabelComponent } from '@stsm/ui-components/form-field/label.component';
import { InputDirective } from '@stsm/ui-components/input';
import { PhoneNumberInputComponent, PhoneNumberInputErrorsComponent } from '@stsm/ui-components/phone-number-input';
import { StepComponent, StepperComponent } from '@stsm/ui-components/stepper';
import { User } from '@stsm/user/models/user';

import { MediaOrientation } from '../../models/media-orientation';
import { AdvertisementMediaComponent } from '../advertisement-media/advertisement-media.component';

import { LeadGenAdModalStep } from './lead-gen-ad.models';
import { LeadGenAdModalService } from './lead-gen-ad-modal.service';
import { LeadGenAdModalLeadQuestionComponent } from './lead-gen-ad-modal-lead-question/lead-gen-ad-modal-lead-question.component';

type LeadGenFormValue = Omit<LeadSubmitData, 'answersToQuestions'>;

type LeadGenFormGroup = ToFormControls<LeadGenFormValue>;

@UntilDestroy()
@Component({
  selector: 'app-lead-gen-ad-modal-form',
  standalone: true,
  imports: [
    TranslatePipe,
    FormFieldComponent,
    InputDirective,
    AdvertisementMediaComponent,
    ButtonComponent,
    NgTemplateOutlet,
    NgIf,
    NgForOf,
    LeadGenAdModalLeadQuestionComponent,
    IllustrationDirective,
    IconButtonComponent,
    FormErrorComponent,
    LabelComponent,
    StepComponent,
    StepperComponent,
    PhoneNumberInputComponent,
    PhoneNumberInputErrorsComponent,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './lead-gen-ad-modal-form.component.html',
  styleUrls: ['./lead-gen-ad-modal-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'data-cy': 'lead-gen-ad-modal' },
})
export class LeadGenAdModalFormComponent implements OnInit {
  @Input({ required: true }) ad!: Ad;

  @Input({ required: true }) user!: User;

  /**
   * Whether the first view with ad creative and company details should be skipped
   */
  @Input() shouldSkipAdDisplay: boolean = false;

  @ViewChild(StepperComponent) stepper: StepperComponent | undefined;

  protected readonly trackByDefault: TrackByFunction<string> = trackByDefault;
  protected readonly mediaOrientation: MediaOrientation = 'landscape';

  protected answersToQuestions: LeadAnswer[] = [];

  protected readonly leadGenForm: FormGroup<LeadGenFormGroup> = new FormGroup<LeadGenFormGroup>({
    firstName: new FormControl<string>('', { nonNullable: true }),
    lastName: new FormControl<string>('', { nonNullable: true }),
    email: new FormControl<string>('', { nonNullable: true }),
    phoneNumber: new FormControl<string>('', { nonNullable: true }),
  });

  get steps(): LeadGenAdModalStep[] {
    const steps: LeadGenAdModalStep[] = [];

    if (this.ad.description && !this.shouldSkipAdDisplay) {
      steps.push('company_details');
    }

    this.ad.leadQuestions.forEach(() => steps.push('questionnaire'));

    steps.push('personal_info');

    return steps;
  }

  get stepsCount(): number {
    return this.steps.length;
  }

  get currentStep(): LeadGenAdModalStep | undefined {
    return this.steps[this.stepper?.currentStepIndex ?? 0];
  }

  get isLastStep(): boolean {
    return this.stepper?.currentStepIndex === this.stepsCount - 1;
  }

  get isFirstStep(): boolean {
    return this.stepper?.currentStepIndex === 0;
  }

  /**
   * Returns true if current step is a custom question and no answer was provided yet, otherwise returns true.
   */
  get isNextButtonDisabled(): boolean {
    if (this._currentCustomQuestion) {
      return (
        this.answersToQuestions.find((customAnswer: LeadAnswer) => customAnswer.questionId === this._currentCustomQuestion?.id)?.answers
          .length === 0
      );
    }

    return false;
  }

  private get _currentCustomQuestion(): LeadQuestion | undefined {
    if (this.currentStep === 'questionnaire') {
      const customQuestionIndex = this.steps
        .slice(0, this.stepper?.currentStepIndex ?? 0)
        .filter((step: LeadGenAdModalStep) => step === 'questionnaire').length;

      return this.ad.leadQuestions[customQuestionIndex];
    }

    return undefined;
  }

  private get _currentCustomQuestionNumber(): number | undefined {
    const currentCustomQuestion = this._currentCustomQuestion;

    return currentCustomQuestion
      ? this.ad.leadQuestions.findIndex((customQuestion: LeadQuestion) => customQuestion.id === currentCustomQuestion.id) + 1
      : undefined;
  }

  constructor(
    private readonly _leadGenAdModalService: LeadGenAdModalService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _translationService: TranslationService,
    private readonly _dialogRef: DialogRef<LeadGenAdModalFormComponent, LeadSubmitData | undefined>,
    private readonly _sentryService: SentryService,
  ) {}

  ngOnInit(): void {
    this._leadGenAdModalService.trackStep({ ad: this.ad, step: this.currentStep, questionNumber: this._currentCustomQuestionNumber });

    this.ad.leadQuestions.forEach((customQuestion: LeadQuestion) =>
      this.answersToQuestions.push({ questionId: customQuestion.id, answers: [] }),
    );

    this.leadGenForm.setValue({
      firstName: this.user.appUser.firstName,
      lastName: this.user.appUser.lastName,
      email: this.user.appUser.email,
      phoneNumber: this.user.phoneNumber,
    });

    this.leadGenForm.controls.email.disable({ emitEvent: false });

    if (this.ad.isPhoneNumberRequired) {
      this.leadGenForm.controls.phoneNumber.addValidators([Validators.required, Validators.pattern(phoneNumberRegex())]);
    }

    if (this.ad.isNameRequired) {
      this.leadGenForm.controls.firstName.addValidators(Validators.required);
      this.leadGenForm.controls.lastName.addValidators(Validators.required);
    }
  }

  protected previous(): void {
    if (isNil(this.stepper) || this.stepper.currentStepIndex <= 0) {
      this.dismiss();

      return;
    }

    this.stepper?.previous();
    this._leadGenAdModalService.trackStep({ ad: this.ad, step: this.currentStep, questionNumber: this._currentCustomQuestionNumber });
  }

  protected next(): void {
    this.stepper?.next();
    this._leadGenAdModalService.trackStep({ ad: this.ad, step: this.currentStep, questionNumber: this._currentCustomQuestionNumber });
  }

  protected submit(): void {
    if (!this.leadGenForm.valid) {
      return;
    }

    const value = this.leadGenForm.getRawValue();

    this.stepper?.reset();

    if (isNil(this.answersToQuestions)) {
      this._sentryService.reportToSentry(`LGA: answersToQuestions is undefined`);
    }

    this._dialogRef.dismiss({ ...value, answersToQuestions: this.answersToQuestions });
  }

  protected dismiss(): void {
    this._simpleDialogService
      .confirm({
        heading: this._translationService.get('AD_REWARDS.CLOSE_DIALOG.TITLE'),
        subheading: this._translationService.get('AD_REWARDS.CLOSE_DIALOG.TEXT'),
        cancelText: this._translationService.get('AD_REWARDS.CLOSE_DIALOG.CANCEL'),
        confirmText: this._translationService.get('AD_REWARDS.CLOSE_DIALOG.CONFIRM_OFFER'),
        allowBackdropDismiss: false,
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((shouldStay: boolean) => {
        if (!shouldStay) {
          this._leadGenAdModalService.trackStep({
            ad: this.ad,
            step: this.currentStep,
            questionNumber: this._currentCustomQuestionNumber,
            event: 'leadgen_form_close',
          });
          this.stepper?.reset();
          this._dialogRef.dismiss();
        }
      });
  }

  protected setLeadQuestionAnswers(leadQuestion: LeadQuestion, answers: string[]): void {
    this.answersToQuestions = this.answersToQuestions.map((answerToQuestion: { questionId: Id; answers: string[] }) => {
      if (answerToQuestion.questionId === leadQuestion.id) {
        return { questionId: answerToQuestion.questionId, answers };
      }

      return answerToQuestion;
    });
  }
}
