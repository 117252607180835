import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { switchToVoid } from '@stsm/shared/util/rxjs.util';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { FirstStepsCompletedDialogComponent } from './first-steps-completed-dialog.component';

@Injectable({ providedIn: 'root' })
export class FirstStepsCompletedDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  openDialog(): Observable<void> {
    const dialogRef = this._platformModalService.create({
      component: FirstStepsCompletedDialogComponent,
      mobileOptions: {
        isAutoHeight: true,
      },
      webOptions: {
        maxWidth: '360px',
      },
    });

    return dialogRef.afterClosed().pipe(switchToVoid());
  }
}
