import { bootstrapApplication } from '@angular/platform-browser';
import { init, replayIntegration } from '@sentry/angular';
import { isNil } from 'lodash-es';

import { isFullMatch } from '@stsm/shared/util/regex.util';
import { webReleaseTagRegex } from '@stsm/shared/util/regex-patterns';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { AppSettings } from './app/app-settings';
import { environment } from './environments/environment';

if (!isNil(environment.SENTRY_DSN)) {
  init({
    dsn: environment.SENTRY_DSN,
    // only use a release for production (exclude release branches by using the regex check)
    release:
      environment.production && isFullMatch({ value: AppSettings.VERSION_NUMBER, regex: webReleaseTagRegex() })
        ? environment.TARGET_MARKET === 'us'
          ? `${AppSettings.VERSION_NUMBER}-vaia`
          : AppSettings.VERSION_NUMBER
        : undefined,
    maxValueLength: 1000,

    // This initializes Replay without starting any session
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    integrations: [
      replayIntegration({
        maskAllText: false,
        // the replay starts only after the user has authenticated
        maskAllInputs: false,
        networkDetailAllowUrls: [environment.BASE_URL],
      }),
    ],
  });
}

bootstrapApplication(AppComponent, appConfig).catch(console.error);
