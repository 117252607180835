<ol class="streak-week">
  @for (weekdayLabel of weekdayLabels$ | async; let index = $index; track index) {
    @let inactive = index <= lastMissedDayIndex();

    <li
      class="streak-calendar-day"
      data-cy="streak-calendar-weekday"
      [attr.aria-label]="weekdayLabelsLong[index]"
      [attr.data-grayed]="inactive || null"
      [attr.data-state]="streakDays()[index]"
      [attr.data-today]="index === todayIndex || null"
      [class.inactive]="inactive"
      [class.today]="index === todayIndex"
      [ngClass]="streakDays()[index]"
    >
      @switch (streakDays()[index]) {
        @case (StreakDayStatus.MISSED) {
          <i class="app-icon-close"></i>
        }

        @case (StreakDayStatus.ACHIEVED) {
          @if (inactive) {
            <img alt="" [src]="StreakIcon.FLAME_INACTIVE" />
          } @else {
            <img alt="" [src]="StreakIcon.FLAME_ACTIVE" />
          }
        }

        @case (StreakDayStatus.OPEN) {
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="14.4" stroke="#98A2B3" stroke-width="1.6" stroke-linecap="round" stroke-dasharray="2 4" />
          </svg>
        }

        @case (StreakDayStatus.IDLE) {
          <i class="app-icon-pause"></i>
        }
      }

      <div class="label">{{ weekdayLabel }}</div>
    </li>
  }
</ol>
