@for (message of messages; track message.id) {
  @if (!message.gptOnly) {
    @if (message.slideset) {
      <app-slideset-card [slideset]="message.slideset" [showProgress]="false" />
    } @else if (message.image && !message.gptOnly) {
      <div class="image-wrapper">
        <img data-cy="ai-assistant-image-preview" alt="" [src]="message.image" />
      </div>
    } @else if (message.uiText || message.text) {
      <app-chat-bubble [message]="message" [@fadeInOut] />
    }
  }
}

@if (isGeneratingResponse) {
  <app-chat-bubble [message]="generatingResponseMessage" />
}
