import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, InputSignal, Signal } from '@angular/core';
import { findLastIndex } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { getWeekdays } from '@stsm/date/functions/get-weekdays';
import { Language } from '@stsm/i18n/models/language';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { StreakDayStatus, WeeklyStreak } from '@stsm/streak/models/streak';
import { StreakIcon } from '@stsm/streak/models/streak-icon';

@Component({
  standalone: true,
  selector: 'app-streak-calendar',
  templateUrl: './streak-calendar.component.html',
  styleUrl: './streak-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgClass],
})
export class StreakCalendarComponent {
  readonly weeklyStreak: InputSignal<WeeklyStreak> = input.required();

  protected readonly streakDays: Signal<StreakDayStatus[]> = computed(() => Object.values(this.weeklyStreak()));

  protected readonly StreakDayStatus: typeof StreakDayStatus = StreakDayStatus;
  protected readonly StreakIcon: typeof StreakIcon = StreakIcon;

  protected readonly weekdayLabels$: Observable<string[]> = this._translationService.language$.pipe(
    map((language: Language) => getWeekdays(language.value, 'narrow')),
  );

  protected readonly weekdayLabelsLong: string[] = getWeekdays(this._translationService.currentLanguage(), 'long');

  protected readonly todayIndex: number = (new Date().getDay() + 6) % 7;

  protected lastMissedDayIndex: Signal<number> = computed(() => {
    return findLastIndex(this.streakDays(), (weekdayStatus: StreakDayStatus) => {
      return weekdayStatus === StreakDayStatus.MISSED;
    });
  });

  constructor(private readonly _translationService: TranslationService) {}
}
