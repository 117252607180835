import { DevToolsEnvironment } from '../services/devtools.service';

export const recaptchaV3SiteKeyFactory: (isProduction: boolean, defaultSiteKey: string) => string = (
  isProduction: boolean,
  defaultSiteKey: string,
) => {
  if (!isProduction) {
    const devToolsEnvironment = localStorage.getItem('devtools_environment');
    const recaptchaDev = '6Le8UsoZAAAAAAA-67_-c6VaDia0hevBkPE2V8aH';
    const recaptchaProd = '6Ld8QK0UAAAAADzNrdSZWBzjAXlsxmBg4gHga-Z_';

    if (devToolsEnvironment) {
      return devToolsEnvironment === DevToolsEnvironment.PROD ? recaptchaProd : recaptchaDev;
    }

    return defaultSiteKey;
  }

  return defaultSiteKey;
};
